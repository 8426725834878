.content-contatti{

	.intro{
		.make-xs-column(12);
		.make-sm-column(8);
		.make-md-column(8);
		margin-bottom: 30px;
		@media @tablet {
			margin-bottom: 50px;
		}
		h1{
			text-transform: uppercase;
			font-size: 34px;
			@media @tablet {
				font-size: 50px;
			}
		}
		h4{
			font-size: 20px;
			font-weight: normal;
			font-style: italic;
		}
	}

	.wrapper-map{
		.make-xs-column(12);
		position: relative;
		margin-bottom: 60px;
		@media @tablet {
			margin-bottom: 80px;
		}
		
	    
		#map{	        
	        border-bottom: 5px solid @ubb-grigio2;			
			height: 596px;
		}
		#map-mobile{
			width: 100%;
			height: 878px;
			background: url(../img/contatti/mappa_mobile.jpg) no-repeat center bottom;
			background-size: cover; 
			border-bottom: 5px solid @ubb-grigio2;
		}
		.map-overlay{
			position: absolute;
			width: 277px;
			@media @tablet {
				width: 320px;
			}

			top:20px;
			left:20px;
			background: @ubb-arancio;
			z-index: 1000;
			font-size: 16px;
			color: @ubb-bianco;
			padding: 20px 0px 22px 25px;
			border-bottom: 3px solid @ubb-grigio2;
			.marker-small{
				float: left;
				margin-right: 39px;
			}
			.address{
				float: left;
				p{
					margin: 0px;
				}
			}
			a{
				margin-top: 20px;
				margin-right: 20px;
				text-transform: uppercase;
				&:hover{
					color: @ubb-bianco;
				}
				i{
					margin-left: 16px;
				}
			}
		}

		.map-detail{
			position: absolute;
			width: 277px;
			@media @tablet {
				width: 320px;
			}
			top:190px;
			left:20px;
			background: @ubb-bianco;
			z-index: 1000;
			font-size: 16px;
			color: @ubb-nero;
			padding: 20px 0px 22px 25px;
			border-bottom: 5px solid @ubb-grigio2;
			p{
				padding-left: 54px;
				padding-bottom: 2px;
				a.no-color{
					color: @ubb-nero;
				}
			}
			.mail{
				width: 100%;
				background: url(../img/icone/mail.svg) no-repeat 0 0px;
				a{
					color: @ubb-nero;
					&:hover{
						color: @ubb-nero;
					}
				}
			}
			.phone{
				width: 100%;
				background: url(../img/icone/telefono_fisso.svg) no-repeat 0 0px;
			}
			.cellular{
				width: 100%;
				background: url(../img/icone/telefono_cellulare.svg) no-repeat 0 0px;
			}
			.fax{
				width: 100%;
				background: url(../img/icone/fax.svg) no-repeat 0 0px;
			}
			.language{
				background: url(../img/icone/fumetto.svg) no-repeat 0 0px;
				width: 100%;
				img{
					display: inline;
					margin-right: 10px;
				}
			}
			
		}
	}

	.cat-contatti{
		.make-xs-column(12);
		margin-bottom: 60px;
		.title-cat-prod{
			margin-bottom: 18px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			border-bottom: 2px solid @ubb-arancio;
			color: @ubb-arancio;
		}

		.elenco-contatti{			
				
			padding-left: 0px;
			padding-right: 0px;

			ul.info-contatto{
				list-style: none;
				margin: 0;
				padding:0;
			 	li{
			 		.make-xs-column(6);
			 		.make-sm-column(4);
			 		.make-md-column(4);
			 		
					padding-left: 0px;
					padding-bottom: 30px;
					@media @tablet{
						padding-bottom: 40px;
					}
			 		display: inline-block;
				
			 		.wrapper-contatto{
						font-size: 13px;
						height: 166px;
						@media @tablet{
							font-size: 16px;
						}

						.identity{
							font-weight: 700;
							margin-top: 10px;
							margin-bottom: 0px;
							text-transform: uppercase;
						}
						.role{
							font-style: italic;
						}
			 			ul.detail{
			 				padding-left: 0px;
			 				@media @tablet{
								padding-left: 12px;
							}

							li{
								display: block;
								width: 100%;
								padding: 8px 0px 4px 30px;
								font-size: 10px;
								@media @tablet{
									font-size: 13px;
									padding-left: 45px;
								}
								&.cellular{
									width: 100%;
									background: url(../img/icone/telefono_cellulare.svg) no-repeat 0 0;								
									a{
										color:@ubb-nero;
									}
								}
								&.mail{
									width: 100%;
									background: url(../img/icone/mail.svg) no-repeat 0 0px;
								}
								&.language{
									width: 100%;
									background: url(../img/icone/fumetto.svg) no-repeat 0 4px;
									img{
										display: inline;
										margin-right: 5px;
										
									}
								}

							}
			 			}	
												
				 	}
						
						
				}
			}
		}
	}

}