/*
.eventi{
	
	background: @ubb-grigio;
	padding-top: 30px;		
	.evento{

		.tipo-data-evento{
			text-transform: uppercase;
			padding-left: 0px;
			.data-evento{
	    		font-size: 11px;
	    		text-transform: uppercase;
	    		padding:0px;
	    		margin:0px;
	    		@media @tablet{
					font-size: 16px;
				}
	    	}
	    	.tipo-evento{
	    		font-size: 11px;
	    		text-transform: uppercase;
	    		padding:0px;
	    		margin:0px;
	    		@media @tablet{
					font-size: 13px;
				}
	    	}
		}
		.txt-evento{
			margin-bottom: 32px;
			.title-evento{
				text-transform: uppercase;
				margin-bottom: 0;
				font-size: 18px;
				font-weight: 800;
				@media @tablet{
					font-size: 21px;
				}
			}
			.txt-intro-evento{
				font-size: 18px;
				padding: 0;
				@media @tablet{
					font-size: 21px;
				}
			}
			.nota-evento{
				font-weight: normal;
				font-size: 18px;
				margin-top: 5px;
				@media @tablet{
					font-size: 13px;
				}
			}
			.bt-link-arancio{
				
				bottom: 0px;
				i{
					padding-left: 18px;
				}
			}
			
		}
	}	
	
}
.caso-studio{	
	
	
	position: relative;
	background: @ubb-arancio;		
	padding-top: 61px;
	padding-bottom: 81px;

	.img-caso-studio{
		
		@media @tablet{
			img{float: right;}
		}
		
	}
	.txt-caso-studio{
		h3{
			color:@ubb-bianco;
			text-transform: uppercase;
			font-size: 25px;
			margin-top: 16px;
			margin-bottom: 0px;
			@media @tablet{
				font-size: 35px;
				margin-top: 20px;
			}
		}
		h6{
			font-weight: normal;
			margin-top: 0px;
			margin-bottom: 0px;
			text-transform: uppercase;
			@media @tablet{
				font-size: 16px;
			}
		}

		.btn{
			margin-top:15px;
			
			@media @tablet{
				margin-top:40px;
			}
		}
		
	}
		
		
	

}
*/


.eventi-caso-studio{
	background: @ubb-grigio;
	// @media @normal{
	// 	background:url("../img/bkg_eventi-caso_studio.png") repeat-y center 0;
	// 	background-size: contain;
	// }
	.eventi{
		
		background: @ubb-grigio;
		padding-top: 30px;
		@media @tablet{
			padding:0;
			padding-top: 70px;
			padding-bottom: 70px;
		}
		@media @normal {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		.img-evento{
			padding: 0 10px;
			&.col-xs-2 {
				@media (max-width: @screen-xs-max) {
					width: 43px;
				}
			}
			@media @tablet{
				padding: 0 5px;
			}
			@media @normal{
				padding: 0 10px;
			}
		}

	 	.tipo-data-evento{
			text-transform: uppercase;
			padding-left: 0px;
			@media @tablet{
				padding-left: 5px;
			}
			@media @normal{
				padding-left: 0px;
			}
			.data-evento{
	    		font-size: 16px;
	    		padding:0px;
	    		margin:0px;
	    		@media @tablet{
					font-size: 16px;
					line-height: 16px;
				}
	    	}
	    	.tipo-evento{
	    		font-size: 13px;
	    		padding:0px;
	    		margin:0px;
	    		@media @tablet{
					font-size: 13px;
				}
	    	}
		}

		.txt-evento{
			margin-bottom: 40px;
			@media @tablet {
				padding: 0;
				margin-bottom: 0;
			}
			// @media @normal {
			// 	padding-bottom: 40px;
			// }
			.title-evento{
				text-transform: uppercase;
				margin-bottom: 0;
				font-size: 19px;
				font-weight: 800;
				@media @tablet{
					font-size: 21px;
				}
			}
			.txt-intro-evento{
				font-size: 19px;
				padding: 0;
				margin:0;
				@media @tablet{
					font-size: 21px;
				}
			}
			.nota-evento{
				font-weight: normal;
				font-size: 18px;
				margin-top: 5px;
				@media @tablet{
					font-size: 26px;
				}
			}
			.bt-link-arancio{
				
				bottom: 0px;
				margin-top: 11px;
				i{
					padding-left: 18px;
				}
			}
			
		}

		.container-evento {
			padding: 0;
		}
		> div {
			&:last-child {
				.container-evento {
					@media @tablet {
						float: right;
					}
				}
			}
		}


	}

	.caso-studio{
		
		position: relative;
		background: @ubb-arancio;		
		padding-top: 60px;
		padding-bottom: 60px;

		@media @tablet{
			padding: 0;
			padding-top: 60px;
			padding-bottom: 80px;
		}
		@media @normal {
			background: transparent;
			padding-top: 66px;
			padding-bottom: 56px;
		}
	
		.img-caso-studio{
		
			@media @tablet{
				img{float: right;}
			}
			
		}
		.txt-caso-studio{
			h3{
				color:@ubb-bianco;
				text-transform: uppercase;
				font-size: 25px;
				margin-top: 16px;
				margin-bottom: 0px;
				@media @tablet{
					font-size: 39px;
					margin-top: 20px;
				}
				@media @normal {
					margin-left: 10px;
				}
			}
			h6{
				font-weight: normal;
				margin-top: 0px;
				margin-bottom: 0px;
				text-transform: uppercase;
				@media @tablet{
					font-size: 16px;
				}
				@media @normal {
					margin-left: 10px;
				}
			}

			.btn{
				margin-top:15px;
				
				@media @tablet{
					margin-top:110px;
				}
				@media @normal {
					margin-left: 10px;
				}
			}
			
		}

	}

}