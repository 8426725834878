.btn{
	font-weight: bold;
	text-transform: uppercase;
	font-size:14px;
	padding: 10px 20px;

	&.btn-default{
		border:0;
		i{
			font-size: 23px;
			vertical-align:-2px;
			font-weight: bold;
			margin-left: 4px;
		}
		&:hover{
			background: @ubb-arancio2;
			
		}
		   
	}
	&.btn-primary{
		border:0;

		i{
			font-size: 23px;
			vertical-align:-2px;
			font-weight: bold;
			margin-left: 4px;
		}
		&:hover{
			background: @ubb-verde_scuro;
			
		}
		&.white {
			color: @ubb-verde_scuro;
			background-color: @ubb-bianco;
		}
		
	}
}

.bt-link-arancio{
	float:right;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;

	i{
		font-size: 16px;
	}
	&:hover{
		color: rgb(247, 170, 15);
	}
}
.bt-link-bianco{
	float:right;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	color:@ubb-bianco;

	i{
		font-size: 16px;
	}
}
