.hero {
    &.home {
        background-color: @ubb-arancio;
        @media @normal{
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        @media @tablet {}
        @media @normal{
            padding: 100px 0;
        }
        .hero-bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @media @normal{
                background-image: none !important;
            }
            &:last-child{
                .hero-blocco {
                    @media @normal{
                        margin-left: 10px;
                    }
                }
            }
        }
        .hero-blocco {
            padding: 58px 10px;
            text-align: center;
            @media @normal{
                padding: 0;
                text-align: left;
            }
            .hero-title {
                font-size: 28px;
                line-height: normal;
                color: @ubb-bianco;
                font-weight: 800;
                margin-top: 0;
                margin-bottom: 7px;
                @media @normal{
                    font-size: 40px;
                    line-height: 45px;
                    margin-bottom: 10px;
                }
            }
            .hero-descrizione {
                font-weight: 700;
                font-style: italic;
                font-size: 13px;
                line-height: normal;
                margin-bottom: 25px;
                color: @ubb-bianco;
                @media @normal{
                    font-size: 16px;
                    margin-bottom: 40px;
                }
            }
        }
    }
    &.scheda{
        background-color: @ubb-arancio;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 300px;
        padding: 90px 0 80px 0;
        color: @ubb-bianco;
        @media @normal{
            height: 312px;
            padding: 60px 0 80px 0;
        }
        h1 {
            line-height: 54px;
            font-weight: 800;
        }
        h4 {
            font-style: italic;
            font-weight: 600;
        }
        p {
            line-height: normal;
            font-weight: 600;
            font-style: italic;
        }
    }
}