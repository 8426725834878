.prodotto.vocabulary-settori-applicativi{
  .intro-cat-prod-prod-cat{
    .txt-cat-prod{
      h1{
        text-transform: uppercase !important;
        font-size: 50px !important;
      }
    }
  }
}
.settori-applicativi{
	background:@ubb-bianco;
	margin-top:0px;
	text-transform: uppercase;
	text-align: center;
	h4{
		margin-top: 20px;
		margin-bottom: 25px;
		font-size: 17px;
		@media @tablet {
			font-size: 20px;
			margin-top: 60px;
			margin-bottom: 66px;
		}

	}

	.img-settori{
		h5{
			font-weight: normal;
		}
		.img-responsive{
			margin: 0 auto;
			width: 55%;
			@media @tablet {
				width: 80%;
			}
			@media @normal{
				width: auto;
			}
		}

		a:nth-child(2){
			display: block;
			padding-top: 10px;
			@media @tablet {
				padding-top: 30px;
			}

		}
	}
	a{
		color: @ubb-text;
		&:hover{
			color: @ubb-arancio2;
		}
	}
	p{
		font-size: 15px;
		margin-top: 12px;
		margin-bottom: 24px;
		@media @tablet {
			font-size: 20px;
			margin-top: 30px;
			margin-bottom: 66px;
			padding: 0 30px;
			line-height: 21px;
		}
	}


	.link-all-settori{
		margin-top: 32px;
		a.bt-link-arancio{
			float:none;
			i{
				margin-left: 17px;
			}
		}

		@media @tablet {
			text-align: right;
			margin-bottom: 20px;
		}
	}
}

.helper-settori-applicativi{
	background:@ubb-bianco;

	.box-helper{
		.make-row();
		.make-xs-column(12);
		border: 3px solid @ubb-arancio;

		margin: 15px 0px 20px 0px;
		padding: 15px 26px 15px 26px;

		@media @tablet{
			margin: 55px 0px 60px 0px;
		}


		div.img-helper{
			text-align: center;
			.make-xs-column(2);
			.make-xs-column-offset(5);
			.make-sm-column(2);
			.make-sm-column-offset(0);
			.make-md-column(2);
			.make-md-column-offset(0);



			@media @tablet{
				padding-top: 30px;
			}
			@media @normal{
				padding-top: 20px;
			}
			.img-responsive{
				margin: 0 auto;
				@media @normal{
					//float: left;
				}
			}

		}
		div.txt-helper{
			text-align: center;

			.make-xs-column(12);
			.make-sm-column(9);
			.make-sm-column-offset(1);
			.make-md-column(7);
			.make-md-column-offset(1);
			p{
				font-style: italic;
				display: none;
				@media @tablet{
					display: block;
					margin-top: 10px;
				}
			}
			h4{
				text-transform: uppercase;
				line-height: 1.2;
				font-weight: 700;
			}

			a.bt-link-arancio{
				float: none;
				text-align: center;
				@media @normal{
					float: none;
				}
				i{
					margin-left: 17px;
				}
			}
		}

	}



}
