.page-404{
	@media @tablet {
		padding-top: 60px;
		padding-bottom: 90px;
	}
	.img-404{
		.make-xs-column(4);
		.make-xs-column-offset(4);
		.make-sm-column(4);
		.make-sm-column-offset(4);
		.make-md-column(4);
		.make-md-column-offset(4);
	}
	.txt-404{
		.make-xs-column(10);
		.make-xs-column-offset(1);
		.make-sm-column(8);
		.make-sm-column-offset(2);
		.make-md-column(8);
		.make-md-column-offset(2);
		color:@ubb-text;
		h3{
			// font-weight: bold;
			font-size: 1.0em;
			text-transform: uppercase;
			text-align: center;
			margin-top: 40px;
			margin-bottom: 0;
			@media @tablet {
				margin-top: 80px;	
			}
		}
		p{
			font-style: italic;
			font-size: 1.0em;
			text-align: center;
			margin-top: 20px;
		}
	}
}