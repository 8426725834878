body.node-type-caso-studio{
	.breadcrumb{
		background-color: transparent;
		position: absolute;
		top:5px;
		left:0;
		z-index: 999;
		margin-left: 10px;
		li{
			color:#fff;
			a{
				color: #fff;
			}
		}
		li.last{
			&:before{
				color: #fff;
			}
		}
	}
}




.bkg-gray{
	background: @ubb-grigio !important;
}
.bkg-white{
	background: @ubb-bianco !important;
}	

.border-white{
	border: 5px solid @ubb-bianco !important;
	@media @tablet{
		border: 10px solid @ubb-bianco !important;
	}
}

.layer-opaco-verde{
	width: auto;
	height:auto;
	background:#0b342c; 
	opacity:0.7;
	position:absolute; 
	width: 100%;
	height:100%;
	filter:alpha(opacity=70); /* For IE8 and earlier */
}

.layer-opaco-arancio{
	width: auto;
	height:auto;
	background:@ubb-arancio; 
	opacity:0.9;
	position:absolute; 
	width: 100%;
	height:100%;
	filter:alpha(opacity=90); /* For IE8 and earlier */
}

.intro-info-detail-verde{
	position: relative;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	@media @normal{
		height: 450px;
	}

	

	.img-intro-info-detail{

		.make-xs-column(5);
		.make-xs-column-offset(3);
		.make-sm-column(4);
		.make-sm-column-offset(1);
		.make-md-column(5);
		.make-md-column-offset(0);
		margin-top: 20px;
		@media @tablet{
			margin-top: 40px;
			margin-bottom: 40px;
		}		

		@media @normal{
			 margin-top: 60px;
			
		}

	}

	.txt-intro-info-detail{
		.make-xs-column(12);
		.make-sm-column(7);
		.make-md-column(6);
		color:@ubb-bianco;
		padding-bottom: 37px;
		h3{
			font-size: 30px;
		}
		p{
			font-style: italic;
		}
		@media @tablet{
			margin-top: 10px;
			margin-bottom: 30px;

			h3{
				font-size: 50px;
			}
			p{
				font-size: 21px;
			}
			padding-bottom: 0px;

		}
		@media @normal{
			margin-top: 35px;
		}


	}
}

.intro-info-detail{
	position: relative;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	@media @normal{
		height: 450px;
	}

	.img-intro-info-detail{

		.make-xs-column(3);
		.make-xs-column-offset(4);
		.make-sm-column(2);
		.make-sm-column-offset(1);
		.make-md-column(3);
		.make-md-column-offset(0);
		margin-top: 33px;
		@media @tablet{
			margin-top: 40px;
			margin-bottom: 40px;
		}		

		@media @normal{
			margin-top: 92px;
			margin-bottom: 0px;
			position: absolute;
		}

	}

	.txt-intro-info-detail{
		.make-xs-column(12);
		.make-sm-column(8);
		.make-sm-column-offset(1);
		.make-md-column(7);
		.make-md-column-offset(2);
		color:@ubb-bianco;
		padding-bottom: 37px;
		text-align: center;
		h1,
		h3{
			font-size: 30px;
			text-transform: uppercase;
		}
		p{
			font-style: italic;
		}
		@media @tablet{
			margin-top: 10px;
			text-align: left;
			h1,
			h3{
				font-size: 50px;
				text-transform: uppercase;
			}
			p{
				font-size: 21px;
			}
			padding-bottom: 0px;

		}
		@media @normal{
			margin-top: 90px;
			margin-left: 317px;
			h3{
				text-transform: uppercase;
			}
		}
	}

}

.info-detail{
	padding-top: 29px;
	padding-bottom: 30px;
	@media @tablet{
		padding-top: 59px;
		padding-bottom: 40px;
	}

	

	.intro-detail{
		.make-xs-column(12);
		.make-sm-column(10);
		.make-sm-column-offset(2);
		.make-md-column(8);
		.make-md-column-offset(4);
		margin-bottom: 0px;
		@media @tablet{
			margin-bottom: 0px;
		}
		.type-detail{
			font-size: 12px;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase;
			margin-bottom: 10px;
			@media @tablet{
				font-size: 13px;
				margin-bottom: 9px;
			}
		}
		.title-detail{
			color:@ubb-arancio;
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase;
			@media @tablet{
				font-size: 21px;
			}
		}
		.text-detail{
			font-size: 13px;
			margin-top: 28px;
			margin-bottom: 26px;
			@media @tablet{
				font-size: 16px;
				margin: 39px 0 40px 0;
			}
		}
		ul{
			font-size: 13px;
			@media @tablet{
				font-size: 16px;
			}
		}
		ul.sub-list{
			list-style-type: none;
			line-height: 1.4em;
		}
		.btn-detail{
			margin-top: 15px;
			@media @tablet{
				margin-top: 20px;
			}
			@media @normal{
				margin-top: 30px;
			}
		}
		
	}

	.img-detail-pagination{
		
		.flexslider-detail-pagination{
			float: left;
			position: relative;
			
			.slides{
				.make-xs-column(12);

				.img-pagination{
					
					.make-xs-column(12);
					.make-sm-column(10);	
					.make-sm-column-push(2);
					.make-md-column(8);
					.make-md-column-push(2);
					.make-md-column-offset(2);	
					padding-left:0px;	
					padding-right:0px;

					img {
						border: 5px solid @ubb-grigio2;
						width:100%;
						@media @tablet{
							border: 10px solid @ubb-grigio2;
						}
						
					}

				}
				.img-dida{
					
					.make-xs-column(12);
					.make-sm-column(2);	
					.make-sm-column-pull(10);
					.make-md-column(2);	
					.make-md-column-pull(8);


					padding-left: 0px;
					padding-right:0px;
					font-size: 11px;
					font-style: italic;
					margin-top: 3px;
					@media @tablet{
						padding-right:10px;
						font-size: 13px;
						text-align: right;
						position:absolute;
						bottom: 0;
						
					}
					@media @normal{
						font-size: 13px;
						text-align: right;
						position: absolute;
						bottom: 0;
					}
				}
			}
			
			.flex-direction-nav{
					display: block;
					li{
						
						a{
							 top:40%;
						}
							
						.flex-prev{
							opacity: 1;
							left: 4%;
							@media @tablet{
								left: 19%;
							}
							@media @normal{
								left: 35%;
							}
								
						}
						.flex-next{
							
							opacity: 1;
							right: 4%;
							@media @tablet{
								right: 3%;
							}
							@media @normal{
								right: 3%;
							}
								
						}						
						
					}
				
			}
			.flex-control-nav {
				.make-xs-column(12);	
				.make-sm-column(6);	
				.make-sm-column-offset(2);	
				.make-md-column(8);	
				.make-md-column-offset(4);	
				bottom:0px;
				text-align: left;
				
				
				
				&.flex-control-paging{
				
					li {

						margin: 0px;
				 		a {
				 			display: inline;
				 			top:32%;
				 			font-size: 10px;
				 			font-weight: 700;
				 			padding-right: 2px;
							width: 0px;
							height: 0px;
							background: inherit;
							border-radius: 0px;
							border:transparent;
							box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
				 			color:@ubb-nero;
							@media @tablet{
								font-size: 13px;					
							}
	
							&:hover{
								color:@ubb-nero;
								color:@ubb-arancio;
							}
				 			&.flex-active{
								background: inherit;
								color:@ubb-arancio;
				 			}
							&:after{
								content: '.';
							}
						}
					}
				}
			}
		}
	
	}
	.img-detail{
		.make-xs-column(12);
		.make-sm-column(12);
		.make-md-column(10);
		.make-md-column-offset(2);
		margin-top: 40px;
		@media @tablet{
			margin-top: 60px;
		}
		img {
			border: 5px solid @ubb-grigio2;
			@media @tablet{
				border: 10px solid @ubb-grigio2;
				width:100%;
			}
			&.no-border{
				border: 0px;
			}
		}
	}

	.container-helper-detail{
		
		.make-xs-column(12);

		display: table;
		margin-top: 30px;
		margin-bottom: 30px;
		@media @tablet{
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.helper-detail-img{
			.make-xs-column(2);
			.make-sm-column(2);
			.make-md-column(1);
			.make-md-column-offset(3);
			display: table-cell;
			vertical-align: middle;
			float: none;

			@media @tablet {
				padding-top: 10px;
			}
			
			img{
				@media @tablet {
					padding-left: 25px;
					padding-right: 25px;
				}
				@media @normal {
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}

		.helper-detail-text{
			.make-xs-column(10);
			.make-sm-column(10);
			.make-md-column(8);
			float: none;
			background: @ubb-grigio;
			display: table-cell;
			padding: 16px 25px 22px 15px;
			font-size: 15px;
			font-style: italic;
			font-weight: 700;


			@media @tablet{
				font-size: 21px;
				padding: 30px 72px 30px 40px;
			}
			
			
			
			.wrapper-helper-number-text{
				display: table;
			
				.text{
					clear: left;
					@media @tablet{
						clear: none;
						display: table-cell;
						vertical-align: middle;
					}
				}
				.number-text{
					clear: left;
					color: @ubb-arancio;
					font-size: 30px;
					font-style: normal;
					@media @tablet{
						clear: none;
						display: table-cell;
						font-size: 50px;
						padding-right: 20px;
						vertical-align: middle;
					}
				}
			}

			
		}
	}
	
	.box-video{
		
		.box-video-img{
			padding: 20px 0; 
			.make-xs-column(3);
			.make-sm-column(2);
			.make-sm-column-offset(2);
			.make-md-column(2);
			.make-md-column-offset(4);

		}
		.box-video-text{
			padding: 20px 0; 
			.make-xs-column(9);
			.make-sm-column(8);
			.make-md-column(6);
			.title{
				font-size: 13px;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 0px;
				@media @tablet{
					font-size: 16px;
				}
			}

			.sub-title{
				font-style: italic;
				font-size: 11px;
				@media @tablet{
					font-size: 13px;
				}
			}
		}
	}

	.intro-vision{
		.make-xs-column(12);
		.make-sm-column(9);
		.make-md-column(9);
		text-align: center;
		vertical-align: middle;
		@media @tablet {
			text-align: left;
		}
		h1{
			text-transform: uppercase;
			margin-top: 5px;
			font-size: 34px;
			@media @tablet {
				font-size: 50px;
			}
		}
		h4{
			font-size: 20px;
			font-weight: normal;
			font-style: italic;
			line-height: 1.2em;
		}
	}

	
}




