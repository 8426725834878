body.node-type-subhome-servizi{
	.breadcrumb{
		background-color: transparent;
		position: absolute;
		top:5px;
		left:0;
		z-index: 999;
		li{
			color:#fff;
			a{
				color: #fff;
			}
		}
		li.last{
			&:before{
				color: #fff;
			}
		}
	}
}

.servizi{
	position: relative;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	@media @normal{
		height: 450px;
	}
	.layer-opaco{
		  width: 100%;
		  height: 100%;
		  background: #0b342c;
		  opacity: 0.7;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  filter: alpha(opacity=70);
	}
	.img-intro-servizi{
		margin-top: 90px;
	}
	.txt-intro-servizi{
		color:#fff;
		margin-top: 90px;
		h1,
		h2{
			font-size: 50px;
  			margin-top: 0px;
  			text-transform: uppercase;;
		}
		p{
			margin-top: 16px;
		}
	}
}