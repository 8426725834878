.ubb_page-header {
	margin-bottom: 0px;
	text-align: center;
	@media @tablet {
		
		text-align: left;
	}
	
}

