// Insert your styling here.
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: absolute;
}
header{

  /*MENU*/
  .navbar{
    margin-bottom: 0;
    height: 60px;
    min-height: 0;

    // @media @tablet {
    //   height: 97px;
    // }
    @media @normal {
      height: 97px;
    }

    .navbar-header{

      /* Pulsante menù mobile */
      .navbar-toggle{
        border-color: transparent;
        border-radius:0;
        height: 57px;
        margin-top: 0;
        margin-right: 0px;
        margin-bottom: 0px;
        padding: 18px 18px;

        /*&:hover{
          background-color: @ubb-arancio;
        }
        &:focus{
          background-color: @ubb-arancio;
        }*/
        &:hover .icon-bar{
          background-color: @ubb-bianco;
        }
        &:focus .icon-bar{
          background-color: @ubb-bianco;
        }

        .icon-bar{
            height: 3px;
            border-radius:0;
            background-color: @ubb-nero;
        }

      }

      /* Logo UBB */
      .navbar-brand{
        padding: 18px 12px;
        height: 50px;

        img{
          height:20px;
        }

        @media @normal {
          padding: 32px 12px;
          img{
            height:31px;
          }
        }
      }

      /* Pulsante lingua mobile */
      a.navbar-lingua{
        text-transform: uppercase;
        color:@ubb-nero;
        padding: 17px 10px;
        float:right;
        &:hover{
          font-weight: bold;
        }
        @media @normal {
          display: none;
        }
      }

    }

    .navbar-collapse {

      section{
        @media @normal {
          float: left;
        }
      }

      z-index:99999;
      /*margin-right: -20px;
      padding-right:0;*/
      padding: 0;
      /*max-height: 814px;*/
      background-color: @navbar-default-bg;

      // @media @tablet {
      //   float: right;
      //   // margin-left: 60px;
      //   // margin-left: 15px;
      //   margin-left: 0px;
      // }
      @media @normal {
        float: right;
        // margin-left: 30px;
        margin-left: 0px;
      }

      ul.navbar-nav{
        margin: 0px;
        width: 100%;
        font-size: 16px;
        border-top:3px solid @navbar-default-bg;

        @media @normal {
          border-top: none;
        }

        ul.nav{
          /*overflow-y: scroll;
          max-height: 500px;*/
          @media @tablet{
            /*overflow-y: visible;
            max-height: none; */
          }
          li.dropdown{
            border-bottom: 3px solid @navbar-default-bg;
              background: @ubb-grigio2;
              @media @normal {
                background: none;
                border-bottom: transparent;
                display: inline-block;
              }

              span.level1{
                font-size: 15px;
                font-weight: normal;
                width: 100%;
                height: 70px;
                line-height: 70px;
                display: inline-block;
                cursor:pointer;
                padding: 0px 10px;
                color: @ubb-text;

              @media @normal {
                font-size: 13px;
                font-weight: normal;
                height: 97px;
                min-width: 105px;
                line-height: 97px;
                padding: 0px;
                text-align: center;
                position: relative;
                &:hover{
                  color: @ubb-arancio2;
                  font-weight: 800;
                }
              }

              @media @normal{
                min-width: 130px;
                /*padding: 0px 50px;*/

              }


                .caret {
                  border: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 0px solid transparent;
                  border-top: 0px solid transparent;
                  float: right;
                  width: 0;
                  height: 0;
                  margin-top: 30px;
                  color: #fff;

                  @media @normal {
                  /*display: none;*/
                  border: 15px solid transparent;
                    border-right: 15px solid transparent;
                }

              }
              .caret.up {
                border-bottom: 10px solid;
                @media @normal {
                  /*display: none;*/
                  border-bottom: 15px solid;
                  position: absolute;
                  bottom: 0;
                  left: 40%;
                }
              }
              .caret.down {
                  border-top: 10px solid;
                  @media @normal {
                    display: none;
                  }
              }
              }

              span.level1-active, span.current-page{
                background: @ubb-arancio;
                color: #fff;
                @media @normal {
                  background: transparent;
                  //font-weight: 800;
                  color: @ubb-text;
                }
              }

            ul.dropdown-menu{

              background-color: #fff;
              padding: 0;
              @media @normal {
                margin:0;
                border: none;
                padding: 0;
                padding-top: 20px;
                width: auto;
              }
              li.item-menu{
                @media @normal {
                  padding-bottom:30px;
                }
                a{
                  padding: 15px 10px;
                  font-size: 15px;
                  font-weight: normal;
                  color: #04291f;
                  @media @normal {
                    padding: 0px 20px;
                    font-size: 12px;
                    font-weight: normal;

                    &:hover{
                      background-color: transparent;
                      color:@ubb-arancio2;
                    }
                    &:focus{
                      background:transparent;
                      color: @ubb-verde_sel_menu;
                    }
                    &.active{
                      background:transparent;
                      color: @ubb-verde_sel_menu;
                    }
                  }

                }



                span{
                  @media @normal {
                    padding: 0px 20px;
                    font-size: 12px;
                    font-weight: normal;
                    cursor: pointer;
                  }
                  .caret {
                    @media @normal {
                      display: none;
                    }
                  }
                }

              }

              li.dropdown-submenu{
                position:relative;
                background: #fff;
                border-top: 5px solid @ubb-grigio;
                border-bottom: 5px solid @ubb-grigio;

                @media @normal{
                  border:none;
                }

                span.level2 {
                    font-size: 15px;
                    font-weight: normal;
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    display: inline-block;
                    cursor:pointer;
                    padding: 0px 10px;
                    color: @ubb-text;

                    @media @normal {
                      font-size: 12px;
                      font-weight: normal;
                      height: auto;
                      line-height: normal;
                      padding: 0px 20px;
                      &:hover{
                        color: @ubb-arancio2;
                      }
                    }

                    @media @normal{
                      height: auto;
                      line-height: normal;
                      padding: 0px 20px;
                    }
                    .caret {
                      border: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 0px solid transparent;
                      border-top: 0px solid transparent;
                      float: right;
                      width: 0;
                      height: 0;
                      margin-top: 30px;
                      color: @ubb-grigio2;
                      @media @normal {
                        /*display: none;*/
                        border: 15px solid transparent;
                        border-right: 15px solid transparent;
                      }

                    }
                    .caret.up {
                      border-bottom: 10px solid;
                      @media @normal {
                        /*display: none;*/
                        border-bottom: 15px solid;
                        position: absolute;
                        bottom: 0;
                        left: 40%;
                      }
                    }
                    .caret.down {
                        border-top: 10px solid;
                        @media @normal {
                          display: none;
                        }
                    }
                    .caret.right{
                        border-left: 15px solid;
                        border-right: 0px;
                        position: absolute;
                        top: -20px;
                        left: 99%;
                        display: block;
                        z-index: 9999;
                        color: @ubb-grigio;
                        padding: 0;
                    }

                }

                span.level2-active{
                  color: @ubb-arancio;
                  @media @normal {
                    color: @ubb-text;
                    font-weight: 700;
                  }
                }

                ul.dropdown-menu{ 
                  background-color: #fff;
                  @media @normal {
                    top:0;
                    left:100%;
                    margin-top:-6px;
                    background-color: #fff;
                    border-left:3px solid @ubb-grigio;
                    min-width: 220px;
                  }
                  @media @normal {
                    left:99%;
                  }
                  li.item-menu{
                    @media @normal {
                      padding-bottom:30px;
                    }
                    a{
                      padding: 15px 10px;
                      font-size: 15px;
                      font-weight: normal;
                      color: #04291f;
                      @media @normal {
                        padding: 0px 20px;
                        font-size: 12px;
                        font-weight: normal;

                        &:hover{
                          background-color: transparent;
                          color:@ubb-arancio2;
                        }
                        &:focus{
                          background:transparent;
                          color: @ubb-verde_sel_menu;
                        }
                        &.active{
                          background:transparent;
                          color: @ubb-verde_sel_menu;
                        }
                      }
                      &.action-link {
                        font-style: italic;
                        text-transform: none;
                        text-decoration: underline;
                        &.active {
                          color: #04291f;
                        }
                      }
                    }



                    span{
                      @media @normal {
                        padding: 0px 20px;
                        font-size: 12px;
                        font-weight: normal;
                        cursor: pointer;
                      }
                      .caret {
                        @media @normal {
                          display: none;
                        }
                      }
                    }

                  }
                  li.dropdown-submenu{
                    position:relative;
                    background: #fff;
                    border-top: 5px solid @ubb-grigio;
                    border-bottom: 5px solid @ubb-grigio;
                    @media @normal{
                      border:none;
                    }
                    span.level3 {
                      font-size: 15px;
                      font-weight: normal;
                      width: 100%;
                      height: 70px;
                      line-height: 70px;
                      display: inline-block;
                      cursor:pointer;
                      padding: 0px 10px;
                      color: @ubb-text;
  
                      @media @normal {
                        font-size: 12px;
                        font-weight: normal;
                        height: auto;
                        line-height: normal;
                        padding: 0px 20px;
                        &:hover{
                          color: @ubb-arancio2;
                        }
                      }
  
                      @media @normal{
                        height: auto;
                        line-height: normal;
                        padding: 0px 20px;
                      }
                      .caret {
                        border: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-top: 0px solid transparent;
                        float: right;
                        width: 0;
                        height: 0;
                        margin-top: 30px;
                        color: @ubb-grigio2;
                        @media @normal {
                          /*display: none;*/
                          border: 15px solid transparent;
                          border-right: 15px solid transparent;
                        }
  
                      }
                      .caret.up {
                        border-bottom: 10px solid;
                        @media @normal {
                          /*display: none;*/
                          border-bottom: 15px solid;
                          position: absolute;
                          bottom: 0;
                          left: 40%;
                        }
                      }
                      .caret.down {
                          border-top: 10px solid;
                          @media @normal {
                            display: none;
                          }
                      }
                      .caret.right{
                          border-left: 15px solid;
                          border-right: 0px;
                          position: absolute;
                          top: -23px;
                          left: 99%;
                          display: block;
                          z-index: 9999;
                          color: @ubb-grigio;
                          padding: 0;
                      }
                    }
                    span.level3-active{
                      color: @ubb-arancio;
                      @media @normal {
                        color: @ubb-arancio2;
                      }
                    }
                    ul.dropdown-menu{
    
                      background-color: @ubb-grigio;
                      @media @normal {
                        top:0;
                        left:100%;
                        margin-top:-6px;
                        background-color: #fff;
                        border-left:3px solid @ubb-grigio;
                        min-width: 220px;
                      }
                      @media @normal {
                        left:99%;
                      }
                      li.item-menu{
                        @media @normal {
                          padding-bottom: 15px;
                        }
                        a{
                          @media @normal {
                            &.active{
                              background:transparent;
                              color: @ubb-verde_sel_menu;
                            }
                            &:focus{
                              background:transparent;
                              color: @ubb-verde_sel_menu;
                            }
                          }
                        }
                        span{
                          @media @normal {
                            padding: 0;
                            margin: 0 20px;
                            width: auto;
                          }
                        }
    
                      }
                      span{
                        margin: 0 10px;
                          border-bottom: 2px solid @ubb-verde;
                        width: 80%;
                        display: block;
                        font-size: 15px;
                        font-style: italic;
                        color: @ubb-verde;
                        text-transform: capitalize;
                        font-weight: 700;
                      }
                      ul{
                        padding: 0px;
                        @media @normal {
                          padding-top: 15px;
                        }
                        li{
                          a{
                            display: block;
                            @media @normal {
    
                            }
                          }
                        }
                      }
                    }
                  }
                } 

              }
              //??
              li.dropdown-submenu.pull-left{
                float:none;
                .dropdown-menu{
                  left:-100%;
                  margin-left:10px;
                  -webkit-border-radius:6px 0 6px 6px;
                  -moz-border-radius:6px 0 6px 6px;
                  border-radius:6px 0 6px 6px;
                }
              }
            }

          }


          li.dropdown.active{
            .level1{
              @media @normal {
                background: none;
                color: @ubb-text;
                font-weight: 800;
              }
              &:hover{
                @media @normal {
                  color: @ubb-arancio2;
                }
              }
            }

            /*
            ul.dropdown-menu{
              li.active{
                  > a{
                    color: @ubb-verde_sel_menu;
                    background: transparent;
                    &:hover{
                      color: @ubb-arancio2;
                    }
                  }

                  a.active{
                    color: @ubb-verde_sel_menu;
                    background: transparent;
                    &:hover{
                      color: @ubb-arancio2;
                    }
                  }

                  span.level2{
                    background: transparent;
                    color: @ubb-arancio2;
                  }
              }

            }*/
          }

          li.item-btn{
            display: inline-block;
            max-width: 100%;
            width: 100%;
            border-bottom: 3px solid #ebf1f1;
            &.active,
            &.focus{
              .btn{
                background-color: @ubb-arancio;
              }
            }
            @media @normal {
              max-width: 270px;
              width: auto;
              border-bottom: 0;
            }
            .btn{
              padding: 25px 10px;
              text-align: left;
              i{
                display: none;
              }
              @media @normal {
                text-align: center;
                padding: 10px 15px;
                i{
                  display: inline-block;
                }
               }
            }
          }
        }
      }

    }

  }
}

section#block-locale-language{
  display: none;
  @media @normal {
    display: table;
    text-align: center;
    height: 97px;
    min-width: 32px;
    float: right;
    margin-left: 40px;
    @media @normal {
      margin-left: 60px;
    }
    ul{
      display: table-cell;
        vertical-align: middle;
      margin: 0;
      padding: 0;
      list-style-type: none;
      li{
        font-size: 13px;
        a{
          text-transform: uppercase;
          color: @ubb-text;
          &:hover{
            color: @ubb-arancio2;
            font-weight: 800;
          }
          &.active{
            font-weight: 800;
          }
        }

      }
    }
    /*margin: 20px 57px 0 0;*/
  }
}
