
footer{
	background: @ubb-nero;
	color: @ubb-bianco;
	font-size: 13px;
	line-height: 2em;
	//height: 325px;
	padding-bottom: 20px !important;
	margin-top: 0px !important;
	border-top: 0px !important;

 	@media @tablet {
		padding-bottom: 50px !important;
	}

	.indirizzo{
		padding-left: 13px;
		@media @tablet {
	 		height: 200px;
		}
		img{
			margin-bottom:10px;
			width:109px;
		}
		.glyphicon{
			margin-right:10px;
		}
		a.phone, a.fax{
			color: @ubb-bianco;
		}
	}

	.social{
		float:right;
		padding-right: 19px;
		text-align: right;
		@media @tablet {
	 		height: 200px;
	    	padding-top: 132px;

		}

		ul.lista-social li{
			vertical-align: middle;
			margin-top: 10px;
		}

	}
	ul.lista-azienda{
		text-align: center;
		margin-bottom: 0px;
		padding-left: 0;
  		list-style: none;
	}
	ul.lista-azienda li{
		margin-top: 10px;

		display: block;
		@media @tablet {
			margin-top: 30px;
			display: inline-block;
		}
		&:first-child{
			font-size: 9px;
			padding-right: 10px;
			@media @tablet {
				font-size: 13px;
			}
		}
	}
}
