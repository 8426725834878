.view-reference{
	.clearfix();
	.make-row();
	.view-filters {
		.make-xs-column(12);
		margin-bottom: 40px;
		@media @normal {
			.make-md-column(3);
		}
	}
	.view-content {
		.make-xs-column(12);
		@media @normal {
			.make-md-column(8);
			.make-md-column-offset(1);
		}
	}
	.views-row {
		.make-row();
		margin-bottom: 60px;
		@media @tablet{
			margin-bottom: 60px;
		}
		@media @normal {
			margin-bottom: 80px;
		}

		.info-ref{
			.make-xs-column(12);
			font-size: 13px;
			font-style: italic;
			font-weight: 600;
			
			@media @normal {
				.make-row();
				font-size: 16px;
			}
			
		}
		.product-ref{
			.clearfix();
			.make-xs-column(12);
			margin-bottom: 10px;
			@media @normal {
				.make-md-column(12);
				margin-left: -10px;
			}
			.product-title{
				font-size: 18px;
				font-weight: 800;
				margin: 0;
				margin-top: 2px;
				margin-bottom: 12px;
				text-transform: uppercase;
				@media @normal {
					margin-top: 0px;
					margin-bottom: 20px;
					font-size: 26px;
				}
			}
			.product-row{
				.clearfix();
				.make-xs-column(12);
				.make-row();
				margin-bottom: 10px;
				@media @normal {
					margin-bottom: 5px;
				}
				.prod-number{
					.make-xs-column(3);
					.make-row();
					font-size: 16px;
					font-weight: 700;
					color: @ubb-arancio;
					@media @tablet{
						.make-sm-column(4);
						font-size: 26px;
					}	
					@media @normal {
						.make-md-column(4);
						font-size: 26px;
					}
						
				}
				.prod-code{
					.make-xs-column(3);
					.make-xs-column-offset(1);
					
					font-size: 13px;
					font-weight: normal;
					padding-top: 5px;
					@media @tablet {
						.make-sm-column(4);
						.make-sm-column-offset(0);
						font-size: 16px;
						padding-top: 8px;
					}
					@media @normal {
						.make-md-column(4);
						.make-sm-column-offset(0);
						font-size: 16px;
						padding-top: 8px;
					}
					
				}
				.prod-image{
					margin-top: 5px;
					.make-xs-column(5);
					
					@media @tablet {
						.make-sm-column(4);
						margin-top: 0px;
					}
					@media @normal {
						.make-md-column(4);
						margin-top: 0px;
					}
					
				}
			}
		}

	}
	.position-pagination{
		text-align: center;
		@media @normal{
			text-align: right;
		}
	}

	.container-helper-detail{
		
		.make-xs-column(12);

		display: table;
		margin-top: 30px;
		margin-bottom: 30px;
		@media @tablet{
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.helper-detail-img{
			.make-xs-column(2);
			.make-sm-column(2);
			.make-md-column(1);
			.make-md-column-offset(3);
			display: table-cell;
			vertical-align: middle;
			float: none;

			@media @tablet {
				padding-top: 10px;
			}
			
			img{
				@media @tablet {
					padding-left: 25px;
					padding-right: 25px;
				}
				@media @normal {
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}

		.helper-detail-text{
			.make-xs-column(10);
			.make-sm-column(10);
			.make-md-column(8);
			float: none;
			background: @ubb-grigio;
			display: table-cell;
			padding: 16px 25px 22px 15px;
			font-size: 15px;
			font-style: italic;
			font-weight: 700;


			@media @tablet{
				font-size: 21px;
				padding: 30px 72px 30px 40px;
			}
			
			
			
			.wrapper-helper-number-text{
				display: table;
			
				.text{
					clear: left;
					@media @tablet{
						clear: none;
						display: table-cell;
						vertical-align: middle;
					}
				}
				.number-text{
					clear: left;
					color: @ubb-arancio;
					font-size: 30px;
					font-style: normal;
					@media @tablet{
						clear: none;
						display: table-cell;
						font-size: 50px;
						padding-right: 20px;
						vertical-align: middle;
					}
				}
			}

			
		}
	}
}

.sub-lead{
	width: 100%;
	.clearfix();
	p{
		font-size: 16px;
		font-weight: 300;
		font-style: normal;
		margin-bottom: 50px;
		@media @normal {
			.make-md-column(7);
			.make-row();
		}
	}
}