/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Ville Ristimäki (@villeristi)
 * 
 */


// ====================================================================================================================
// LESS SNIPPETS
// ====================================================================================================================*/


//
// Transition
//

.transition( @duration: 1s, @easing: ease ) {

  -webkit-transition: all @duration @easing;
     -moz-transition: all @duration @easing;
      -ms-transition: all @duration @easing;
       -o-transition: all @duration @easing;
          transition: all @duration @easing;

}


//
// Border-radius
//

.border-radius( @radius: 5px ){

  -webkit-border-radius: @arguments;
     -moz-border-radius: @arguments;
          border-radius: @arguments;

}


//
// Box-shadow
//

.box-shadow( @x-axis: 0, @y-axis: 1px, @blur: 2px, @color: rgba( 0, 0, 0, 0.1 ), @inset: '' ) {
  
  -webkit-box-shadow: @inset @x-axis @y-axis @blur @color;
     -moz-box-shadow: @inset @x-axis @y-axis @blur @color;
       -o-box-shadow: @inset @x-axis @y-axis @blur @color;
          box-shadow: @inset @x-axis @y-axis @blur @color;

}





/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/


@font-face {

  font-family: 'flexslider-icon';
  src:url('../fonts/flexslider-icon.eot');
  src:url('../fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/flexslider-icon.woff') format('woff'),
    url('../fonts/flexslider-icon.ttf') format('truetype'),
    url('../fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;

}



/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/


.flex-container,
.flex-slider{

  a:hover,
  a:focus{

    outline: none;

  }

}

.slides,
.flex-control-nav,
.flex-direction-nav {
  
  margin: 0; 
  padding: 0; 
  list-style: none;

}

.flex-pauseplay span {

  text-transform: capitalize;

}





/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/


.flexslider {

  margin: 0; 
  padding: 0;
  overflow: hidden;

  .slides {

    > li {

      display: none;

      -webkit-backface-visibility: hidden;

    }

    img{

      width: 100%;
      display: block;

    }


    //
    // Clearfix for the .slides element
    //

    &:after{

      content: "\0020"; 
      display: block; 
      clear: both; 
      visibility: hidden; 
      line-height: 0; 
      height: 0;


    }

    html[xmlns] &{
      
      display: block;

    }

    * html &{
      
      height: 1%;

    }


    
     // 
     // No JavaScript Fallback
     // If you are not using another script, such as Modernizr, make sure you
     // include js that eliminates this class on page load
     // 
    

    .no-js &{

      > li{

        &:first-child{

          display: block;

        }
      }
    }
  }
}





/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/


.flexslider { 

  margin: 0 0 60px;
  background: #fff; 
  border: 4px solid #fff; 
  position: relative;
  zoom: 1; 

  .border-radius( 4px );
  .box-shadow( 0, 1px, 4px, rgba( 0, 0, 0, 0.2 ));

  .slides{

    zoom: 1;

  }
}

.flex-viewport { 

  max-height: 2000px; 

  .transition();

  .loading &{

    max-height: 300px;

  }
}

.carousel li { 

  margin-right: 5px; 

}


//
// Direction Nav
//

.flex-direction-nav {

  *height: 0;

  
  //
  // Global styles
  //

  a  { 

    text-decoration:none; 
    display: block; 
    width: 50px; 
    height: 50px; 
    margin: 0px 0 0; 
    position: absolute; 
    top: 50%; 
    z-index: 10; 
    overflow: hidden; 
    opacity: 0; 
    cursor: pointer; 
   // color: rgba(0,0,0,0.8); 
    //text-shadow: 1px 1px 0 rgba( 255, 255, 255, 0.3 );

    .transition( 0.3s, ease-in-out );

    &:before  { 

      // font-family: "flexslider-icon"; 
      // font-size: 40px; 
      // display: inline-block; 
      // content: '\f001';


    }

    &.flex-next:before { 

      //content: '\f002';

      

    }

  }


  //
  // Prev
  //

  .flex-prev { 
    text-indent: 9999px;
    //left: -50px;
    background: url('../img/icone/direction-left.png') no-repeat 10px 5px;
  }


  //
  // Next
  //

  .flex-next { 
    text-indent: 9999px;
    //right: -50px; 
    text-align: right;
    background: url('../img/icone/direction-right.png') no-repeat 10px 5px;
  }


  //
  // Hover states
  //

  .flexslider:hover &{
    
    .flex-prev { 

      opacity: 0.7; 
      left: 10px;

      &:hover{
        
        opacity: 1;

      }

    }
    
    .flex-next { 

      opacity: 0.7; 
      right: 10px;

      &:hover{
        
        opacity: 1;
        
      }

    }

  }


  //
  // Disabled
  //

  .flex-disabled { 

    opacity: 0!important; 
    filter: alpha(opacity=0); 
    cursor: default;

  }
}


//
// Pause/Play
//

.flex-pauseplay a {

  display: block; 
  width: 20px; 
  height: 20px; 
  position: absolute; 
  bottom: 5px; 
  left: 10px; 
  opacity: 0.8; 
  z-index: 10; 
  overflow: hidden; 
  cursor: pointer; 
  color: #000;

  &:before { 

    font-family: "flexslider-icon"; 
    font-size: 20px; 
    display: inline-block; 
    content: '\f004';

  }

  &:hover {

    opacity: 1;

  }

  .flex-play:before { 

    content: '\f003';

  }

}


//
// Control Nav
//

.flex-control-nav {

  width: 100%; 
  position: absolute; 
  bottom: -40px; 
  text-align: center;

  li {
    
    margin: 0 6px; 
    @media @tablet{
      margin: 0 0 0 19px; 
    }
    display: inline-block; 
    zoom: 1; 
    *display: inline;

  }

}


//
// Control paging
//

.flex-control-paging li {

  a {

    width: 11px; 
    height: 11px; 
    display: block; 
    background: #666; 
    background: rgba(0,0,0,0.5); 
    cursor: pointer; 
    text-indent: -9999px;

    .box-shadow( 0, 0, 3px, rgba(0,0,0,0.3), inset );
    .border-radius( 20px );

    &:hover { 

      background: #333; 
      background: rgba(0,0,0,0.7);

    }

    &.flex-active { 

      background: #000;
      background: rgba( 0, 0, 0, 0.9 );
      cursor: default;

    }
  }
}


.flex-control-thumbs {

  margin: 5px 0 0; 
  position: static; 
  overflow: hidden;

  li {

    width: 25%; 
    float: left; 
    margin: 0;

  }

  img {
  
    width: 100%; 
    display: block; 
    opacity: .7; 
    cursor: pointer;

    .transition();

    &:hover {opacity: 1;}

  }

  .flex-active {

    opacity: 1; 
    cursor: default;

  }
}





/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/

//@media screen and ( max-width: 860px ) {

  .flex-direction-nav{

    .flex-prev { 

      opacity: 1; 
      left: 10px;

    }

    .flex-next { 

      opacity: 1; 
      right: 10px;

    }
  }
//}
