form{
    .form-consulenza{
        margin: 50px auto;
        .form-header{
            margin-bottom: 50px;
            h1{
                font-size: 34px;
                font-weight: bold;
                line-height: 46px;
                text-transform: uppercase;
                @media @tablet {
                    font-size: 50px;
                    line-height: 68px;
                }
            }
            h4{
                font-weight: normal;
                font-style: italic;
                font-size: 20px;
                line-height: 27px;
                @media @tablet {
                    font-size: 21px;
                    line-height: 28px;
                }
                p {
                    font-size: 20px !important;
                    line-height: 27px;
                    @media @tablet {
                        font-size: 21px !important;
                        line-height: 28px;
                    }
                }
            }
            .mandatory-fields{
                color: @ubb-grigio4;
                font-size: 13px;
                line-height: 23px;
            }
        }
        .form-group{
            margin-bottom: 50px;
            label{
                font-size: 16px;
                line-height: 22px;
                font-weight: normal;
                margin-bottom: 10px;
            }
            span.label-error{
                position: absolute;
                // bottom: 18px;
                bottom: initial;
                font-size: 13px;
                font-weight: 600;
                color: @ubb-error;
                font-style: italic;
                display: block;
            }
            &.wrap-upload{
                visibility: hidden;
            }
            &.wrap-checkbox-privacy{
                margin-bottom: 35px;
                label{
                    font-size: 13px;
                    line-height: 20px;
                    margin: 0;
                }
                label.label-error{
                    padding: 0;
                    bottom: -18px;
                }
            }
            &.wrap-checkbox-newsletter{
                margin-bottom: 15px;
                label{
                    margin: 0;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
            &.webform-component-markup{
                margin-bottom: 15px;
                font-size: 13px;
                line-height: 20px;
                &.webform-component--newsletter-markup2{
                    margin-bottom: 35px;
                }
                &.webform-component--mailchimp-markup{
                    display: table;
                    margin-bottom: 50px;
                    p{
                        display: table-cell;
                        vertical-align: top;
                        font-size: 11px;
                        line-height: 15px;
                        margin: 0;
                        img{
                            margin-right: 10px;
                        }
                    }
                }
            }
            .form-control{
                height: 50px;
                background-color: @ubb-grigio;
                font-size: 16px;
                line-height: 22px;
                font-weight: 600;
                padding: 14px 15px;
                border: 0;
                border-bottom: 2px solid @ubb-grigio;
                box-shadow: none;
                &.label-error{
                    background-color: @ubb-error-bg;
                    border-bottom: 2px solid @ubb-error;
                    .select2-choice{
                        background-color: @ubb-error-bg;
                        border-bottom: 2px solid @ubb-error;
                    }
                }
                &.form-select-custom{
                    padding: 0;
                }
                &.form-textarea{
                    height: 150px;
                    resize: none;
                    @media @tablet {
                        height: 86px;
                    }
                }
                &.form-file{
                    opacity: 0;
                    z-index: -1;
                    width: 0.1px;
                    height: 0.1px;
                    overflow: hidden;
                    position: absolute;
                    + label{
                        padding: 0;
                        display: table;
                        width: 100%;
                        cursor: pointer;
                        font-size: 14px;
                        overflow: hidden;
                        font-weight: bold;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        strong{
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 14px;
                            padding: 10px 20px;
                            color: @ubb-bianco;
                            background-color: @ubb-arancio;
                            display: table-cell;
                            text-align: center;
                            vertical-align: middle;
                            width: 140px;
                        }
                        span{
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: table-cell;
                            vertical-align: middle;
                            color: @ubb-link;
                            padding: 0 15px;
                        }
                    }
                }
                &:focus{
                    box-shadow: none;
                    border-color: @ubb-link;
                }
                &::-moz-placeholder {
                    font-weight: normal;
                    color: @ubb-text;
                    opacity: 0.5;
                }
                &:-ms-input-placeholder {
                    font-weight: normal;
                    color: @ubb-text;
                    opacity: 0.5;
                }
                &::-webkit-input-placeholder {
                    font-weight: normal;
                    color: @ubb-text;
                    opacity: 0.5;
                }
                &::placeholder {
                    font-weight: normal;
                    color: @ubb-text;
                    opacity: 0.5;
                }
            }
        }
        .form-actions {
            > div+button {
                margin-top: 30px;
            }
        }
    }
}
.response-form{
    display: none;
    padding: 75px 0;
    @media @tablet {
        padding: 100px 0;
    }
    &.consulenza{
        text-align: center;
        img{
            &.img_ok{
                width: 135px;
                @media @tablet {
                    width: 206px;
                }
            }
            &.img_ko{
                width: 135px;
                @media @tablet {
                    width: 170px;
                }
            }
            height: auto;
        }
        h1{
            font-size: 22px;
            line-height: 30px;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 50px;
            @media @tablet {
                font-size: 28px;
                line-height: 38px;
            }
        }
        p{
            font-size: 20px;
            line-height: 27px;
            font-style: italic;
        }
        .btn{
            margin-top: 50px;
        }
    }
}

.webform-confirmation{
    &.response-success{
        .response-form-success{
            display: block
        }
        .response-form-failed{
            display: none
        }
    }
    &.response-failed{
        .response-form-success{
            display: none
        }
        .response-form-failed{
            display: block
        }
    }
}
