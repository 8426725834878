// Insert your styling here.
.space-container{
	margin-bottom: 40px;
}

#cookieChoiceInfo{
	bottom: 0 !important;
	top: auto !important;
	padding: 10px !important;
	background-color: @ubb-nero !important;
	opacity:0.9 !important;
	filter:alpha(opacity=90) !important;
	border-top: 2px solid @ubb-grigio;
	font-size: 13px;
	
	@media @tablet {
		font-size: 16px;
	}
	span {
  		color: #fff;
  		display:block;
		padding-bottom: 5px;
  		@media @tablet {
  			margin-right: 10px;
  			padding-bottom: 10px;
  		}
	}
	a {
	    background-color: #fff;
		color: @ubb-arancio2;
		text-decoration: none;
		padding: 7px 10px;
	  	display: inline-block;
	  	width: 100%;
		margin: 2px !important;
		font-weight: bold;
		@media @tablet {
			width: auto;
		}
	}
}