.casi-studio-hp{

	.intro-prod{
		.make-xs-column(12);
		.make-sm-column(9);
		.make-md-column(9);
		margin-bottom: 105px;
		h1{
			text-transform: uppercase;
			font-size: 34px;
			@media @tablet {
				font-size: 50px;
			}
		}
		h4{
			font-size: 20px;
			font-weight: normal;
			font-style: italic;
			line-height: 1.2em;
		}
		p{
			line-height: 1.6em;
		}
	}
	.caso-studio-box{
		margin-bottom: 30px;
		@media @tablet {
			margin-bottom: 60px;
		}
		.make-xs-column(12);
		.make-md-column(12);
		background: @ubb-arancio;

		.caso-studio-box-img{
			.make-xs-column(3);
			.make-xs-column-offset(4);
			.make-sm-column(3);
			.make-sm-column-offset(1);
			.make-md-column(3);
			.make-md-column-offset(1);

			margin-top: 25px;
			@media @tablet{
				margin-top: 30px;
				margin-bottom: 30px;
			}
		}
		.caso-studio-box-txt{
			.make-xs-column(12);
			.make-sm-column(6);
			.make-sm-column-offset(1);
			.make-md-column(6);
			.make-md-column-offset(1);
			color: @ubb-bianco;
			h3{
				font-size: 30px;
				text-transform: uppercase;
				@media @tablet{
					font-size: 40px;
					margin-top: 59px;
				}
			}
			p{
				font-style: italic;
			}
			.btn{
				margin-top: 37px;
				margin-bottom: 40px;
				@media @tablet{
					margin-bottom: 28px;
				}
			}
		}
	}



}
