.view-comunicazione {
	.clearfix();
	.make-row();
	.view-filters {
		.make-xs-column(12);
		margin-bottom: 40px;
		.form-control{
			padding: 0;
			border: 0;
		}
		@media @tablet{
			.views-widget{
				float: left;
				margin-right: 10px;
			}
		}
		@media @normal {
			.make-md-column(3);
			.views-widget{
				float: none;
				margin-right: 0;
			}
		}
	}
	.view-content {
		.make-xs-column(12);

		@media @normal {
			.make-md-column(8);
			.make-md-column-offset(1);
		}
	}
	.views-row {
		
		margin-bottom: 20px;
		@media @normal {
			.clearfix();
			.make-row();
			margin-bottom: 80px;
		}
		
		.info-item{
			.clearfix();
			.make-xs-column(12);
			margin-bottom: 10px;
			@media @tablet{
				.make-sm-column(3);
				text-align: right;
			}
			@media @normal {
				.make-md-column(3);
				text-align: right;
			}
			.info-icon{
				float: left;
				width: 11%;
				@media @normal {
					width: 15%;
				}
			}
			.info-publi{
				float: left;
				width: 75%;
				p{
					margin-left: 10px;
					margin-bottom: 0px;
					font-weight: normal;
				}
				p.publi-date{
					font-size: 16px;
				}
				p.publi-cat{
					font-size: 13px;
					text-transform: uppercase;
				}
				@media @tablet{
					width: 60%;
				}
				@media @normal{
					width: 75%;
				}
			}
		}
		.article-item{
			.clearfix();
			.make-xs-column(12);
			margin-bottom: 10px;
			@media @tablet {
				.make-sm-column(9);
				margin-left: -10px;
			}
			@media @normal {
				.make-md-column(9);
				margin-left: -10px;
			}
			.article-title{
				font-size: 18px;
				font-weight: 800;
				margin: 0;
				margin-top: 10px;
				text-transform: uppercase;
				@media @tablet {
					margin-top: 0px;
					font-size: 26px;
				}
				
			}
			.article-sub-title{
				font-size: 18px;
				font-weight: normal;
				margin-top: 5px;
				margin-bottom: 10px;
				@media @normal {
					font-size: 26px;
				}
			}
			.article-date{
				font-size: 13px;
				font-weight: normal;
				margin-top: 10px;
				margin-bottom: 10px;
				@media @normal {
					margin-top: 20px;
				}
			}
			a{
				cursor: pointer;
			}

		}
	}
	.position-pagination{
		text-align: center;
		@media @normal{
			text-align: right;
		}
	}

	.container-helper-detail{
		
		.make-xs-column(12);

		display: table;
		margin-top: 30px;
		margin-bottom: 30px;
		@media @tablet{
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.helper-detail-img{
			.make-xs-column(2);
			.make-sm-column(2);
			.make-md-column(1);
			.make-md-column-offset(3);
			display: table-cell;
			vertical-align: middle;
			float: none;

			@media @tablet {
				padding-top: 10px;
			}
			
			img{
				@media @tablet {
					padding-left: 25px;
					padding-right: 25px;
				}
				@media @normal {
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}

		.helper-detail-text{
			.make-xs-column(10);
			.make-sm-column(10);
			.make-md-column(8);
			float: none;
			background: @ubb-grigio;
			display: table-cell;
			padding: 16px 25px 22px 15px;
			font-size: 15px;
			font-style: italic;
			font-weight: 700;


			@media @tablet{
				font-size: 21px;
				padding: 30px 72px 30px 40px;
			}
			
			
			
			.wrapper-helper-number-text{
				display: table;
			
				.text{
					clear: left;
					@media @tablet{
						clear: none;
						display: table-cell;
						vertical-align: middle;
					}
				}
				.number-text{
					clear: left;
					color: @ubb-arancio;
					font-size: 30px;
					font-style: normal;
					@media @tablet{
						clear: none;
						display: table-cell;
						font-size: 50px;
						padding-right: 20px;
						vertical-align: middle;
					}
				}
			}

			
		}
	}

}

.lead{
	width: 100%;
	margin: 0px;
	.clearfix();
	p{
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: normal;
		line-height: 1.2em;
		font-style: italic;
		@media @normal {
			.make-md-column(7);
			.make-row();
		}
	}
}

.line-separator {
	.make-xs-column(12);
	border-top: 2px solid @ubb-arancio;
	margin-top:5px;
	margin-bottom: 5px;
}
