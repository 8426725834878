.content-slogan{
	position: relative;

	background: @ubb-verde;
	color: @ubb-bianco;
	text-transform: uppercase;

	.wrapper-slogan{
		height: 100%;
	

		.txt-slogan{
			padding-left: 0px;
			padding-right:0px;
			text-align: center;
			h1,
			h3{
		  		font-size: 15px;
		  		margin-top: 13px;
		  		margin-bottom: 13px;
		  	}
		  	

			@media @tablet{
				h1,
				h3{
			  		display: block;
			  		font-size: 25px;
			  		margin-top: 30px;
		  			margin-bottom: 27px;
			  		
			  	}
			}
			
		}
	}
	
	
}