

.node-referenza.node-teaser {
	.ds-meta {
		text-align: center;
		text-transform: uppercase;
		font-size: 13px;
		margin-bottom: 5px;
		font-style: italic;
		a {
			color: @ubb-nero;
			text-decoration: none;
			&:hover {
				color: @ubb-arancio;
			}
		}
	}
	.ds-text {
		text-align: center;
		h2 {
			font-size: 18px;
			text-transform: uppercase;
			color:@ubb-bianco;
			margin-top: 0;
			a {
				color: @ubb-nero;
				font-weight: bold;
				text-decoration: none;
				&:hover {
					text-decoration: none;
					color: @ubb-arancio;
				}
			}
			@media @tablet {
				font-size: 26px;
			}
		}
		.field-name-field-number {
			
			color: @ubb-nero;
			font-size: 26px;
			font-weight: 700;
			line-height: 28px;
		}
		.field-name-title-field {
			padding-top: 6px;
			text-transform: uppercase;
		}
	}
	.ds-cta {
		a {
			font-size: 13px;
			text-transform: uppercase;
			font-weight: bold;
			&:after {
				margin-left: 10px;
				.glyphicon();
				content:"\e080";
			}
		}
	}
	// Stili tabella
}

.node-news.view-mode-full {
	.main-col {
		.make-xs-column(12);
		@media @normal {
			.make-md-column(8);
		}
		.page-title {
			font-weight: 800;
			@media @tablet {
				font-size: 26px;
			}
			@media @normal {
				margin-bottom: 1.5em;
			}
		}
	}
	.right-sidebar {
		padding-top: 20px;
		position: relative;
		.make-xs-column(12);
		@media @normal {
			.make-md-column(3);
			.make-md-column-offset(1);
		}
		.file {
			display: block;
			a {
				color: @ubb-nero;
				text-transform: uppercase;
				&:hover {
					color: @ubb-arancio;
					text-decoration: none;
				}
			}
		}
		.label-above {
			font-size: 13px;
			color: @ubb-verde;
			border-bottom: 2px solid @ubb-verde;
			font-style: italic;
			padding: 40px 0 3px;
			margin-bottom: 30px;
		}
	}
	.ds-cta {
		.make-xs-column(12);
		padding-top: 40px;
		a {
			font-size: 13px;
			text-transform: uppercase;
			font-weight: bold;
			&:before {
				margin-right: 10px;
				.glyphicon();
				content:"\e079";
			}
		}
		
	}
	.backto {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: bold;
		position: absolute;
		top: 0;
		right: 10px;
		display: none;
		&:before {
			margin-right: 10px;
			.glyphicon();
			content:"\e079";
		}
		@media @normal {
			display: block;
		}
	}

}