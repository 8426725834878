.slider{

	.flexslider {
		padding: 0;
		border: 0;
		margin-bottom: -1px;
		

		.slides>li {
			position: relative;
			text-align: center;
			
			@media @tablet{
				text-align: left;
			}
			@media @normal{
				text-align: left;
			}
			color:@ubb-bianco;
			
			.bg_slide {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-repeat: no-repeat;
				background-position: top center;
				background-size: cover;


				.layer-opaco{
					width: 100%;
					height:100%;
					background:#0b342c; 
					opacity:0.7;
					position:absolute; 
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					filter:alpha(opacity=70); /* For IE8 and earlier */
				}

			}
			.container{
				height: 402px;

				@media @tablet{
					height: 458px;
				}
				@media @normal{
					height: 550px;
				}
				.img-txt{
					margin-top: 22px;
					@media @tablet{
						margin-top: 60px;
					}
					@media @tablet{
						margin-top: 100px;
					}
					
				}
				
				.txt-slider{
					font-size: 14px;
					margin-top:30px;
					margin-bottom: 22px;
					.btn{
						margin-top:18px;
					}

					@media @mintablet{
						margin-top: 10px;
					}
					
					@media @tablet{
						margin-top: 62px;
						margin-bottom: 0px;
					}
					@media @normal{
						margin-top: 129px;
					}
					
					h2{
	 					text-transform: uppercase;
	 					font-size: 20px;
	 					margin-top: 0px;
	 					margin-bottom: 0px;
					} 
					p.text-slide{
						margin-top: 20px;

					}

					@media @mintablet{
						p.text-slide{
							margin-top: 15px;
						}
						.btn{
							margin-top: 2px;
						}
					}

					@media @tablet{
						h2{
							font-size: 40px;
						} 
						p.text-slide{
							margin-top: 32px;
							font-size: 18px;
						}
						.btn{
							margin-top:40px;
						}
					}
					@media @normal{
						h2{
							font-size: 40px;
							margin-top: 0px;
						} 
						p.text-slide{
							margin-top: 66px;
						}
						.btn{
							margin-top:40px;
						}
						
					}
					
					
				}
			}

		}
		.flex-control-nav{
			bottom:0px;
			@media @normal{
				display: none;
			}
			@media @mintablet{
				//bottom: 5px;
			}
			@media @tablet{
				left: 24px;
				bottom:58px;
			}
			@media @normal{
				display: none;
			}
			> li{
				line-height: 10px;
				a{
					width: 5px;
					height:5px;

					@media @tablet{
						width: 12px;
						height:12px;
					}

					background: @ubb-grigio3;
					&:hover{
						background: @ubb-bianco;
					}
					&.flex-active{
						background: @ubb-bianco;
					}
				}
			}
		}
		.flex-direction-nav{
			display: none;
			@media @normal{
				display: block;
			}
		}
	}
}


/* SLIDER PAGINA SERVIZI */


.slider-servizi{

	.flexslider {
		padding: 0;
		border: 0;
		margin-bottom: -1px;
		

		.slides>li {
			position: relative;
			text-align: center;
			
			@media @tablet{
				text-align: left;
			}
			@media @normal{
				text-align: left;
			}
			color:@ubb-bianco;
			
			.bg_slide {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-repeat: no-repeat;
				background-position: top center;
				background-size: cover;


				.layer-opaco{
					width: 100%;
					height:100%;
					background:#0b342c; 
					opacity:0.7;
					position:absolute; 
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					filter:alpha(opacity=70); /* For IE8 and earlier */
				}

			}
			.container{
				height: 400px;

				@media @tablet{
					height: 450px;
				}
				@media @normal{
					height: 450px;
				}
				.img-txt{
					margin-top: 22px;
					@media @tablet{
						margin-top: 60px;
					}
					@media @tablet{
						margin-top: 100px;
					}
					
				}
				
				.txt-slider{
					font-size: 14px;
					margin-top:30px;
					margin-bottom: 0px;
					.btn{
						margin-top:18px;
					}

					@media @mintablet{
						margin-top: 10px;
					}
					
					@media @tablet{
						margin-top: 62px;
					}
					@media @normal{
						margin-top: 129px;
					}
					
					h2{
	 					text-transform: uppercase;
	 					font-size: 20px;
	 					margin-top: 0px;
	 					margin-bottom: 0px;
					} 
					p.text-slide{
						margin-top: 20px;

					}

					@media @mintablet{
						p.text-slide{
							margin-top: 15px;
						}
						.btn{
							margin-top: 2px;
						}
					}

					@media @tablet{
						h2{
							font-size: 40px;
						} 
						p.text-slide{
							margin-top: 32px;
							font-size: 18px;
						}
						.btn{
							margin-top:40px;
						}
					}
					@media @normal{
						h2{
							font-size: 40px;
							margin-top: 0px;
						} 
						p.text-slide{
							margin-top: 66px;
						}
						.btn{
							margin-top:40px;
						}
						
					}
					
					
				}
			}

		}
		.flex-control-nav{
			bottom:0px;
			@media @normal{
				display: none;
			}
			@media @mintablet{
				bottom: 5px;
			}
			@media @tablet{
				left: 24px;
				bottom:58px;
			}
			@media @normal{
				display: none;
			}
			> li{

				a{
					width: 5px;
					height:5px;
					@media @tablet{
						width: 12px;
						height:12px;
					}

					background: @ubb-grigio3;
					&:hover{
						background: @ubb-bianco;
					}
					&.flex-active{
						background: @ubb-bianco;
					}

					


				}
			}
		}
		.flex-direction-nav{
			display: none;
			@media @normal{
				display: block;
			}
		}
	}
}





