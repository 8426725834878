.video-homepage {
    margin-bottom: 40px;
    @media @normal {
        margin-bottom: 80px;
    }
    .field-name-field-titolo-video {
        text-align: center;
        .field-items {
            display: inline-block;
            font-size: 20px;
            line-height: normal;
            color: @ubb-verde_scuro;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 8px;
            @media @normal {
                margin-bottom: 40px;
            }
        }
    }
    .player-homepage {
        .ytp-large-play-button-bg {
            fill: @ubb-arancio;
        }
        // .ytp-cued-thumbnail-overlay:hover .ytp-large-play-button-bg
    }
}