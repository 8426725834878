.content-reference{
	text-align: center;
	background: @ubb-arancio;
	color:@ubb-nero;
	text-transform: uppercase;
	

	.title-reference{	
		.make-xs-column(12);
		margin-top: 23px;
		margin-bottom: 23px;
		@media @tablet {
			margin-top: 64px;
			margin-bottom: 48px;
		}
		@media @tablet {
			margin-top: 54px;
			margin-bottom: 48px;
		}
		a.bt-link-nero{
			color:@ubb-nero;
			font-size: 17px;
			font-weight: 700;
			@media @tablet {
				font-size: 20px;
			}
			i{
				font-size: 26px;
				font-weight: 800;
				margin-left: 15px;
			}

			&:hover{
				color:@ubb-bianco;
			}

		}
		
	}

	.swiper-container {
	    width: 100%;
	    height: auto;
	    .swiper-button-next{
	    	background-image: url('../img/icone/reload.png');
	    	background-repeat: no-repeat;
	    	background-size: 17px 15px;

	    	left: 0;
	    	right: 0;
	    	top:0;
	    	bottom: 0;
	    	margin: auto;
	    	width: 37px;
	    	height: 35px;
	    }
	}   


	/*.wrapper-reference:nth-last-child(-n+3){
		display: none;
		@media @tablet {
			display:block;
		}
	}*/
	

	.wrapper-reference{
		.make-xs-column(12);
		/*.make-sm-column(4);*/
		
		text-align: center;
		margin-bottom: 28px;
		width: 100%;
		
		.text-reference{
			margin-top: 23px;
			clear: both;

			.make-sm-column(12);
			
			.reference-data-place{
				font-style: italic;
				font-size: 13px;
				text-transform: capitalize;
				@media @tablet {
					font-size: 17px;
				}
			}
			h4{
				color: @ubb-bianco;
				margin-top: 8px;
				font-size: 18px;
				@media @tablet {
					font-size: 21px;
					line-height: 1.3;
				}

			}
			.reference-number{
				font-size: 26px;
				font-weight: 800;
				margin-bottom: 12px;
				@media @tablet {
					font-size: 35px;
				}
				@media @normal {
					font-size: 40px;
				}
			}

		}
		.image-reference{
			ul{

				list-style: none;
				margin: 0px; 
				padding: 0px;

				li{
					display: inline-block;
					padding:0px 10px;
					

				}
				
			}
		}
		
	}
	.reload-reference{
		.make-xs-column(12);
		height: 50px;
	}

}