.content-policy{
	.intro{
		.make-xs-column(12);
		margin-bottom: 30px;
		h1{
			font-size: 34px;
		}
		@media @tablet {
			margin-bottom: 50px;
		}
	}
	.wrapper-policy{
		.make-xs-column(12);
		margin-bottom: 60px;
		@media @tablet {
			margin-bottom: 80px;
		}
		.row-policy{
			margin-bottom: 40px;
			h3{
				margin-bottom: 18px;
			  	text-transform: uppercase;
			  	font-size: 14px;
			  	font-weight: bold;
			  	border-bottom: 2px solid #f7aa0f;
			  	color: #f7aa0f;
			}
			p{
				font-size: 16px;
				margin-bottom: 10px;
			}
			ul{
				list-style-type: decimal;
				padding-left: 30px;
			}
		}
		
	}
}