body.template-viteria{
	.breadcrumb{
		background-color: transparent;
		position: absolute;
		top:5px;
		left:0;
		z-index: 999;
		li{
			color:#fff;
			a{
				color: #fff;
            }
            &:before{
                color: #fff;
            }
		}
		li.last{
			&:before{
				color: #fff;
			}
		}
	}
}
.content-viteria {
    padding: 40px 0 0 0;
    @media @normal{
        padding: 50px 0 0 0;
    }
    .intro {
        padding-bottom: 30px;
    }
    .list {
        .product {
            padding: 30px 0;
            @media @normal{
                padding: 40px 0;
            }
            .image {
                .make-xs-column(12);
                .make-md-column(3);
                margin-bottom: 20px;
                img {
                    width: 110px;
                    @media @normal{
                        width: 100%;
                    }
                }
            }
            .text {
                .make-xs-column(12);
                .make-md-column(7);
                .make-md-column-offset(1);
                h3 {
                    font-size: 24px;
                    font-weight: 800;
                    line-height: normal;
                    margin-top: 0;
                    margin-bottom: 10px;
                    @media @normal{
                        font-size: 34px;
                    }
                }
                p {
                    font-size: 13px;
                    font-weight: normal;
                    line-height: normal;
                    margin-bottom: 11px;
                    @media @normal{
                        font-size: 16px;
                    }
                }
                .bt-link-arancio {
                    float: none;
                    font-size: 13px;
                    i {
                        margin-left: 10px;
                    }
                }
            }
            &.right {
            }
            &:nth-of-type(even) {
                @media @normal{
                    .image {
                        float: right;
                    }
                }
            }
        }
    }
    .cta-casi-studio{
        margin: 40px 0;
        a {
            display: block;
            font-size: 20px;
            line-height: normal;
            @media @normal{
                font-size: 25px;
                padding: 23px 20px;
            }
            i {
                font-weight: normal;
                vertical-align: baseline;
            }
        }
    }
}

.subhome-viteria {
    .intro {
        .make-xs-column(12);
        margin-bottom: 60px;
        .intro-img {
            .make-xs-column(6);
            .make-xs-column-offset(3);
            .make-sm-column(4);
            .make-sm-column-offset(0);
            .make-md-column(3);
            .make-md-column-offset(0);

            img{margin: 0 auto;}
        }
        .intro-txt {
            .make-xs-column(12);
            .make-sm-column(7);
            .make-md-column(8);
            .make-md-column-offset(1);
            text-align: center;
            vertical-align: middle;
            @media @tablet {
                text-align: left;
            }
            .title {
                margin-top: 15px;
                font-size: 16px;
                @media @tablet {
                    font-size: 14px;
                    margin-top: 0px;
                }
            }
        }
    }
    .divider {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        border-top: 2px solid @ubb-arancio;
    }
    .info-detail {
        padding-bottom: 0;
    }
}