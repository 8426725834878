.detail-news{
	
	
	.article{
		

			// .type-article{
			// 	border:1px solid red;
			// 	.make-xs-column(2);
			// 	.make-sm-column(2);

			// 	display: table-cell;
			// 	vertical-align: middle;
			// 	padding-left: 4px;
			// 	img{
			// 		margin-top: 0px;
			// 		margin-bottom: 0px;
			// 	}
			// }
		.date-article{
			background: url('../img/icone/calendario.svg') no-repeat 0 0;
			background-size: contain;
			.make-xs-column(12);
			padding-left: 30px;
			margin-bottom: 15px;
			
		}
		
		
		.make-xs-column(12);
		.make-sm-column(8);
		padding-bottom: 30px;
		padding-top: 30px;
		@media @tablet {
			padding-bottom: 158px;
			padding-top: 60px;
		}
		h1,
		h2{
			text-transform: uppercase;
			font-size: 30px;
			@media @tablet {
				font-size: 26px;
			}
		}
		p.article-sub-title{
			font-size: 18px;
			font-weight: normal;
			margin-top: 5px;
			margin-bottom: 10px;
		}
		img{
			margin-top: 30px;
			margin-bottom: 49px;
		}
		a.bt-link-arancio{
			float:left;
			margin-top: 30px;
			margin-bottom: 30px;
			@media @tablet {
				margin-bottom: 0px;
			}

			i{
				padding-right: 10px;
			}
		}
	}

	.attach{
		
		.make-xs-column(12);
		.make-sm-column(3);
		.make-sm-column-offset(1);
		padding-top: 30px;
		@media @tablet {
			padding-top: 60px;
		}
		font-size: 16px;
		@media @tablet {
			font-size: 13px;
		}
		.back-list-article, .back-list-article-mobile{
			float: right;
			margin-bottom: 0px;
			@media @tablet {
				margin-bottom: 100px;
			}
			.bt-link-arancio{
				i{
					padding-right: 10px;
				}
			}
		}
		.back-list-article-mobile{
			margin-top: 40px;
			margin-bottom: 100px;
			float: left;
		}

		
		.title{
			clear: both;
			color:@ubb-verde;			
			font-weight: 700;
			font-style: italic;
			border-bottom: 2px solid @ubb-verde;
			margin-bottom: 32px;
		}
		.tipo-allegato{
			background: url('../img/icone/pdf.svg') no-repeat 0 0;
			font-size: 16px;
			padding-left: 45px;
			padding-bottom: 4px;
			margin: 0px 0 30px 13px;
		}
	}
}	

