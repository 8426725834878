.content-vision{
	 

	.flexslider-content-vision {
		padding: 0;
		border: 0;
		margin-bottom: -1px;

		.slides>li {
			position: relative;
			text-align: center;
			padding-top: 30px;
			padding-bottom: 50px;
			@media @tablet{
				text-align: left;
			}
			@media @normal{
				text-align: left;
			}
			color:@ubb-bianco;


			
			.bg_slide {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-repeat: no-repeat;
				background-position: top center;
				background-size: cover;

				

			}
			.container{
				height: 247px;
				@media @tablet{
					height: 409px;
				}
				@media @normal{
					height: 502px;
				}
				.txt-vision{
					font-size: 14px;					
					margin-top:50px;
					
					h2{
						margin-top:0px;
						margin-bottom:0px;
	 					text-transform: uppercase;
	 					font-size: 24px;

					} 
					p{
						margin-top: 20px;
					}
					.btn{
						margin-top:32px;
					}
					@media @tablet{
						margin-top:70px;
						font-size: 18px;
						h2{
							font-size: 35px;
						} 
						p{
							margin-top: 37px;
						}
						.btn{
							margin-top:28px;
						}
					}
					@media @normal{
						margin-top:98px;
						h2{
							font-size: 40px;
						}

						p{
							margin-top:40px;

						}
						.btn{
							margin-top:32px;
						}
					}
					
					
				}
			}

		}
		
	}
	
	
	
}