.content-product{
  .img-prod{
    .make-xs-column(4);
    .make-xs-column-offset(4);
    .make-sm-column(4);
    .make-sm-column-offset(0);
    .make-md-column(4);
    .make-md-column-offset(0);
    margin-top: 30px;

  }
  .intro{
    .make-xs-column(12);
    .make-sm-column(8);
    .make-md-column(7);

    h1{
      text-align: center;
      text-transform: uppercase;
      font-size: 30px;
      @media @tablet {
        font-size: 50px;
        text-align: left;
      }
    }
    h4{
      text-align: center;
      font-size: 16px;
      @media @tablet {
        font-size: 20px;
        text-align: left;
      }
      font-weight: normal;
      font-style: italic;
    }
    p{
      text-align: center;
      font-size: 13px;
      margin-bottom: 30px;
      @media @tablet {
        font-size: 16px;
        text-align: left;
      }
    }
    .tech-info{
      margin-bottom: 30px;
      @media @tablet {
        margin-bottom: 40px;
      }
      .title-tech-info{
        clear: left;
        text-align: left;
        text-transform: uppercase;
        font-size: 13px;
        @media @tablet {
          font-size: 16px;
        }
        font-weight: 700;
      }
      .txt-tech-info{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        img{
          float: left;
          margin-right: 10px;
        }
        div{
          padding-top: 20px;

        }
      }
    }



  }



    .img-detail-pagination{

    .flexslider-detail-pagination{
      float: left;
      position: relative;
      width: 100%;
      .slides{
        .make-xs-column(12);

        .img-pagination{

          .make-xs-column(12);
          .make-sm-column(10);
          .make-sm-column-push(2);
          .make-md-column(8);
          .make-md-column-push(2);
          .make-md-column-offset(2);
          padding-left:0px;
          padding-right:0px;

          img {
            border: 5px solid @ubb-grigio2;

            @media @tablet{
              border: 10px solid @ubb-grigio2;
            }

          }

        }
        .img-dida{

          .make-xs-column(12);
          .make-sm-column(2);
          .make-sm-column-pull(10);
          .make-md-column(2);
          .make-md-column-pull(8);

          padding-left: 0px;
          padding-right:0px;
          font-size: 11px;
          font-style: italic;
          margin-top: 3px;
          @media @tablet{
            padding-right:10px;
            font-size: 13px;
            text-align: right;
            position:absolute;
            bottom: 0;

          }
          @media @normal{
            font-size: 13px;
            text-align: right;
            position: absolute;
            bottom: 0;
          }
        }
      }

      .flex-direction-nav{
          display: block;
          li{

            a{
               top:40%;
            }

            .flex-prev{
              opacity: 1;
              left: 4%;
              @media @tablet{
                left: 19%;
              }
              @media @normal{
                left: 35%;
              }

            }
            .flex-next{

              opacity: 1;
              right: 4%;
              @media @tablet{
                right: 3%;
              }
              @media @normal{
                right: 3%;
              }

            }

          }

      }
      .flex-control-nav {
        .make-xs-column(12);
        .make-sm-column(6);
        .make-sm-column-offset(2);
        .make-md-column(8);
        .make-md-column-offset(4);
        bottom:0px;
        text-align: left;



        &.flex-control-paging{

          li {

            margin: 0px;
            a {
              display: inline;
              top:32%;
              font-size: 10px;
              font-weight: 700;
              padding-right: 2px;
              width: 0px;
              height: 0px;
              background: inherit;
              border-radius: 0px;
              border:transparent;
              box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
              color:@ubb-nero;
              @media @tablet{
                font-size: 13px;
              }

              &:hover{
                color:@ubb-nero;
                color:@ubb-arancio;
              }
              &.flex-active{
                background: inherit;
                color:@ubb-arancio;
              }
              &:after{
                content: '.';
              }
            }
          }
        }
      }
    }


  }


  .box-video{
    .make-xs-column(12);
    .make-sm-column(9);
    .make-sm-column-offset(3);
    .make-md-column(8);
    .make-md-column-offset(4);
    margin-top: 35px;
    @media @tablet{
      margin-top: 70px;
    }
    .make-row();
    .box-video-img{
      .make-xs-column(3);
      .make-sm-column(3);

      @media @tablet{
        padding-left: 0;
      }
    }
    .box-video-text{
      .make-xs-column(8);
      .make-sm-column(9);
      .title{
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0px;
        @media @tablet{
          font-size: 16px;
        }
      }

      .sub-title{
        font-style: italic;
        font-size: 11px;
        @media @tablet{
          font-size: 13px;
        }
      }
    }
  }
}

.content-product-table{
  background: @ubb-grigio;
  padding: 30px 0 30px 0;
  margin-top: 30px;
  @media @tablet {
    margin-top: 60px;
    padding: 60px 0 60px 0;
  }
  .wrapper-product-table{

    .img-product-table{
      .make-xs-column(4);
      .make-xs-column-offset(4);
      .make-sm-column(4);
      .make-sm-column-offset(4);
      .make-md-column(4);
      .make-md-column-offset(0);


      @media @tablet {
        padding-bottom: 30px;
      }

    }
    .wrapper-table{

      .make-xs-column(12);
      .make-sm-column(12);
      .make-md-column(8);


      .table-responsive{
        border: 0px;
        margin-bottom: 22px;
        .table{
          border:3px solid @ubb-bianco;
          margin-top: 40px;
          margin-bottom: 0;
          @media @tablet {
            margin-top: 0px;
          }
          &.table-product{
            width: 100%;

            thead{
              tr{
                background: @ubb-bianco;
                text-transform: uppercase;
                font-size: 8px;
                @media @tablet {
                  font-size: 10px;
                }
                th{

                  border-bottom: 0px;
                  padding: 8px 8px 20px 8px;
                  vertical-align: top;
                  img{
                    display: inherit;
                    margin: 0 auto;

                  }
                  p{
                    margin: 0px;
                    text-align: center;

                    &:last-child{
                      text-transform: initial;
                    }
                  }



                }
              }
            }

            tbody{
              tr{
                font-size: 10px;
                @media @tablet {
                  font-size: 16px;
                }
                td{
                  display: none;
                  border: 0px;
                  text-align: center;
                }

              }
            }
          }
        }
      }
      .show-detail-table{
        display: none;
        margin-bottom: 22px;
        margin-top: -15px;
        &:focus,
        &:active{
          outline: 0;
        }
        span{
          margin-right: 5px;
        }
      }

      .wrapper-info-product{
        .make-xs-column(12);
        .info-product{
          .make-xs-column(12);
          border:3px solid @ubb-bianco;
          padding: 13px 14px 14px 14px;
          font-size: 13px;
          @media @tablet {

            font-size: 16px;
            //padding: 15px 14px 0px 14px;
          }
          .icona-info-product{

            .make-xs-column(2);
            .make-sm-column(2);
            margin-bottom: 14px;

          }
          .txt-info-product{

            .make-xs-column(12);
            .make-sm-column(10);
            @media @tablet {
              //float: left;
            }
            ul{
              padding: 0px;
              li{
                list-style-position: inside;
                margin-bottom: 20px;
              }
            }
          }

        }
      }

    }

    .wrapper-attach{
      .make-xs-column(12);
      .make-sm-column(12);
      .make-md-column(8);
      margin-top: 40px;
      .tipo-allegato{
        font-size: 12px;
        height: 24px;
        background: url('../img/icone/pdf.svg') no-repeat 0 0;
        padding-left: 45px;
        padding-bottom: 4px;
        margin: 0px 0 30px 13px;
        @media @tablet {
          font-size: 16px;
          margin: 0px 0 30px 30px;
          height: auto;
        }
        a{
          color: @ubb-text;
          &:hover{
            color: @ubb-arancio2;
          }
        }
      }
    }


  }
}


.content-application-areas{
  background: @ubb-grigio2;
  padding: 30px 0 30px 0;
  @media @tablet {
    padding: 60px 0 60px 0;
  }
  .wrapper-application-areas{
    .make-xs-column(12);

    h4{
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color:@ubb-bianco;
      text-align: left;


    }


    .panel-group{
      h4{
        font-size: 12px;
        font-weight: 700;
        color:@ubb-nero;
        @media @tablet {
          font-size: 14px;
        }
        a{

          i{
            margin-right: 19px;
            font-size: 10px;
            @media @tablet {
              margin-right: 40px;
            }
          }
          &:hover, &:after{
            color:@ubb-arancio;
            i{
              content:  "\f068";
            }
          }
        }

      }


      .panel-body{
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .sotto-settore{
      .panel-body{
        padding: 0px 0px;
        border-top: 0px;
        .panel-group{
          margin-bottom: 0px;
          .panel-default{
            border-left: 0px;
            border-right: 0px;
            border-bottom: 0px;
          }
          .panel-heading{
            background: @ubb-bianco;


          }

        }
        .sotto-settore-prodotti{
          .panel-default{

            .panel-heading{
              padding-bottom: 10px;
              h4{
                margin-left: 21px;
                text-transform:initial;
                @media @tablet {
                  margin-left: 40px;
                }
              }
            }


            .sotto-settore-prodotto-lista-tipi {
              .panel-body{
                ul{
                  list-style-type: none;
                  margin-left: 40px;
                  font-size: 12px;
                  @media @tablet {
                    margin-left: 90px;
                    font-size: 14px;
                  }
                  li{
                    text-transform: initial;
                    font-weight: normal;

                  }
                }
              }
            }
          }
        }


      }

    }


  }
}



.container-related-prod{
  margin-bottom: 50px;

  @media @tablet {
    margin-bottom: 60px;
  }

  .related-prod{

    .make-xs-column(12);
    margin-top: 40px;
    @media @tablet {
      margin-top: 80px;
    }
    h4{

      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color:@ubb-arancio;
      text-align: left;

    }
    .img-related-prod{
      margin-top: 20px;
      ul{
        list-style: none;
        margin: 0;
        padding:0;

        li:nth-child(-n+3){
          display: block;
        }

        li{
          display: none;
          .make-xs-column(6);
          .make-sm-column(4);
          color: @ubb-nero;
          height: 300px;

          @media @tablet {

            padding-left: 30px;
            padding-right:30px;
          }
          a{
            color: @ubb-nero;

            &:hover{
              color: @ubb-arancio;
            }
          }
          .wrapper-title-material{
            margin-top: 15px;

            .title-related-prod{
              text-transform: uppercase;
              font-weight: 700;


            }
            .material{
              margin-top: 10px;

              img{
                display: inline-block;
                margin-right: 6px;
                padding: 5px 0 5px 0;


                @media @tablet{

                  margin-left: 3px;
                  margin-right: 0px;
                  padding: 0px 0 5px 0;



                }

                &.alluminio{
                  margin-right: 22px;
                  @media @tablet {
                    margin-right: 20px;

                  }
                }
              }
            }

            p{
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              text-transform: none;
              margin-top: 5px;
            }


          }
        }
      }

    }

  }
  .bt-mostra-prod{
    float:right;
    padding-right: 10px;
    &:visited{
      color:@ubb-arancio;
    }
    i{
      margin-left: 10px;
      font-weight:700;
    }
  }
}
