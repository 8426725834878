.view-news {
	.clearfix();
	.make-row();
	.view-filters {
		.make-xs-column(12);
		margin-bottom: 40px;
		.form-group .form-group{
			margin-bottom: 0;
		}
		@media @normal {
			.make-md-column(3);
			
		}
	}
	.view-content {
		.make-xs-column(12);
		@media @normal {
			.make-md-column(8);
			.make-md-column-offset(1);
		}
	}
	.views-row {
		.make-row();
		margin-bottom: 40px;
		@media @normal {
			margin-bottom: 80px;
		}
		.ds-meta {
			.make-xs-column(12);
			@media @normal {
				.make-md-column(3);
				text-align: right;
				
			}
		}
		.ds-text {
			.make-xs-column(12);
			@media @normal {
				.make-md-column(8);
				
			}
		}
		.ds-cta {
			.make-xs-column(12);
			@media @normal {
				.make-md-column(12);
				text-align: right;
				
			}
		}
	}
	.text-center {
		.make-xs-column(12);
		@media @normal {
			text-align: right;
		}
	}
}

