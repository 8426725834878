.prodotto{
  .intro-prod{
    .make-xs-column(12);
    .make-sm-column(8);
    .make-md-column(8);
    margin-bottom: 60px;
    h1{
      text-transform: uppercase;
      font-size: 34px;
      @media @tablet {
        font-size: 50px;
      }
    }
    h4{
      font-size: 20px;
      font-weight: normal;
      font-style: italic;
      line-height: 1.2em;
    }
    p{
      line-height: 1.6em;
    }
  }

  .intro-cat-prod-prod-cat{
    .make-xs-column(12);
    margin-bottom: 60px;

    .img-cat-prod{
      .make-xs-column(6);
      .make-xs-column-offset(3);
      .make-sm-column(4);
      .make-sm-column-offset(0);
      .make-md-column(3);
      .make-md-column-offset(0);

      img{margin: 0 auto;}
    }

    .txt-cat-prod{
      .make-xs-column(12);
      .make-sm-column(7);
      .make-md-column(6);
      text-align: center;
      vertical-align: middle;
      @media @tablet {
        text-align: left;
      }
      .title-cat-prod{
        margin-top: 15px;
        font-size: 16px;
        @media @tablet {
          font-size: 14px;
          margin-top: 0px;
        }
      }
      h1{
        font-size: 34px;
      }
      h4{
        font-size: 20px;
        font-weight: normal;
        font-style: italic;
        line-height: 1.2em;
      }

    }


  }

  .cat-prod{
    .make-xs-column(12);
    margin-bottom: 60px;
    .title-cat-prod{
      margin-bottom: 18px;
      padding-bottom: 6px;
      text-transform: uppercase;
      @media @tablet{
        font-size: 18px;
      }
      font-weight: bold;
      border-bottom: 2px solid @ubb-arancio;
      color: @ubb-arancio;
    }
    .elenco-prod-cat{

      .make-xs-column(12);
      text-align: center;
      ul{
        list-style: none;
        margin: 0;
        padding:0;
        li{
          .make-xs-column(6);
          .make-sm-column(3);
          padding-bottom: 15px;
          min-height: 188px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.3 !important;
          display: inline-block;
          &:nth-child(2n+1) {
            clear: left;
          }
          @media @tablet{
            &:nth-child(2n+1) {
              clear:none;
            }
            &:nth-child(4n+1) {
              clear: left;
            }
          }
          img{
            margin: 15px auto;
          }
          a{
            color: @ubb-text;
            &:hover{
              color: @ubb-arancio2;
            }
          }

        }
      }
    }

  }

  .cat-type-prod{

    .make-xs-column(12);
    margin-top: 18px;
    background: @ubb-grigio;
    display: table;

    .img-type-prod{
      .make-xs-column(5);
      .make-xs-column-offset(4);
      .make-sm-column(4);
      .make-sm-column-offset(0);
      display: table-cell;
      vertical-align: middle;
      @media @tablet{
        float: none;
        padding-top: 20px;
          padding-left: 40px;
      }
    }
    .txt-type-prod{
      .make-xs-column(10);
      .make-xs-column-offset(1);
      .make-sm-column(8);
      .make-sm-column-offset(0);

      @media @tablet{
        float: none;
      }
      display: table-cell;
      vertical-align: middle;
      h4{
        color: @ubb-arancio;
        font-size: 20px;
        text-transform: uppercase;
        @media @tablet{
          font-size: 26px;
        }
      }
      p{

        @media @tablet{
          font-size: 21px;
        }
      }
    }

  }
  .cat-type-prod-button{

    .make-xs-column(12);
    background: @ubb-grigio;
    padding-right: 0px;

    .bt-accordion{

      .make-xs-column(8);
      .make-xs-column-offset(4);
      .make-sm-column(4);
      .make-sm-column-offset(8);
      padding-right: 0px;
      padding-top: 20px;

      .btn-default{
        &:after{
          .fa-icon();
             content:'\f067';
             color: @ubb-bianco;
             margin-left: 43px;
        }
      }

      .btn-click{
        border-right: 2px solid @ubb-grigio2;
        background: @ubb-bianco;
        color: @ubb-arancio;
        &:after{
          .fa-icon();
             content:'\f068';
             margin-left: 43px;
        }

      }


      a{
        width: 100%;

      }
    }
  }

  .container-prod-list{
    display: none;
    .make-xs-column(12);

    border-left:2px solid @ubb-grigio2;
    border-right:2px solid @ubb-grigio2;
    border-bottom:2px solid @ubb-grigio2;
    margin-top: -1px;
  }
  .cat-type-prod-list{

      .title-type-prod{

        margin-top: 18px;
        padding-bottom: 6px;
        text-transform: uppercase;
        @media @tablet {
          font-size: 18px;
          padding-left: 20px;
        }
        font-weight: bold;
        border-bottom: 2px solid @ubb-arancio;
        color: @ubb-arancio;
      }
      .related-prod{

        .make-xs-column(12);
        margin-top: 20px;

        h4{

          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          color:@ubb-arancio;
          text-align: left;

        }
        .img-related-prod{
          margin-top: 20px;
          ul{
            list-style: none;
            margin: 0;
            padding:0;

            li{

              .make-xs-column(6);
              .make-sm-column(4);
              color: @ubb-nero;
              height: 300px;

              @media @tablet {
                padding-left: 30px;
                padding-right:30px;
              }
              a{
                color: @ubb-nero;

                &:hover{
                  color: @ubb-arancio;
                }
              }
              .wrapper-title-material{
                margin-top: 15px;

                .title-related-prod{
                  text-transform: uppercase;
                  font-weight: 700;


                }
                .material{
                  margin-top: 10px;

                  img{
                    display: inline-block;
                    margin-right: 6px;
                    padding: 5px 0 5px 0;


                    @media @tablet{

                      margin-left: 3px;
                      margin-right: 0px;
                      padding: 0px 0 5px 0;



                    }

                    &.alluminio{
                      margin-right: 22px;
                      @media @tablet {
                        margin-right: 20px;

                      }
                    }
                  }
                }

                p{
                  font-size: 12px;
                  font-weight: normal;
                  text-align: left;
                  text-transform: none;
                  margin-top: 5px;
                }


              }
            }
          }

        }

      }
      }
  .cat-prod-select{

    .make-xs-column(12);
    margin-top: 20px;
    margin-bottom: 60px;

    .select-cat-prod{
      padding-top: 18px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      border-top: 2px solid @ubb-arancio;

      .form-item{
        .make-xs-column(12);
        .make-sm-column(3);
        display: inline;
        margin-bottom: 20px;
        max-width: 365px;
      }

      .related-prod{

        .make-xs-column(12);
        margin-top: 30px;

        h4{

          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          color:@ubb-arancio;
          text-align: left;

        }
        .img-related-prod{
          margin-top: 20px;
          ul{
            list-style: none;
            margin: 0;
            padding:0;

            li{

              .make-xs-column(6);
              .make-sm-column(4);
              .make-md-column(4);
              color: @ubb-nero;
              height: 300px;

              @media @tablet {

                padding-left: 30px;
                padding-right:30px;
              }
              a{
                color: @ubb-nero;

                &:hover{
                  color: @ubb-arancio;
                }
              }
              .wrapper-title-material{
                margin-top: 15px;

                .title-related-prod{
                  text-transform: uppercase;
                  font-weight: 700;


                }
                .material{
                  margin-top: 10px;

                  img{
                    display: inline-block;
                    margin-right: 6px;
                    padding: 5px 0 5px 0;


                    @media @tablet{

                      margin-left: 3px;
                      margin-right: 0px;
                      padding: 0px 0 5px 0;



                    }

                    &.alluminio{
                      margin-right: 22px;
                      @media @tablet {
                        margin-right: 20px;

                      }
                    }
                  }
                }

                p{
                  font-size: 12px;
                  font-weight: normal;
                  text-align: left;
                  text-transform: none;
                  margin-top: 5px;
                }


              }
            }
          }

        }

      }
    }

  }

/*.container-helper-detail{

    .make-xs-column(12);

    display: table;
    margin-top: 30px;
    margin-bottom: 30px;
    @media @tablet{
      margin-top: 60px;
      margin-bottom: 60px;
    }
    .helper-detail-img{
      .make-xs-column(2);
      .make-md-column(2);
      .make-md-column-offset(2);
      display: table-cell;
      vertical-align: middle;
      float: none;

    }

    .helper-detail-text{
      .make-xs-column(10);
      .make-md-column(8);
      float: none;
      background: @ubb-grigio;
      display: table-cell;
      padding: 16px 25px 22px 15px;
      font-size: 15px;
      font-style: italic;
      font-weight: 700;


      @media @tablet{
        font-size: 21px;
        padding: 30px 72px 30px 40px;
      }

      .wrapper-helper-number-text{
        display: table;

        .text{
          clear: left;
          @media @tablet{
            clear: none;
            display: table-cell;
            vertical-align: middle;
          }
        }
        .number-text{
          clear: left;
          color: @ubb-arancio;
          font-size: 30px;
          font-style: normal;
          @media @tablet{
            clear: none;
            display: table-cell;
            font-size: 50px;
            padding-right: 20px;
            vertical-align: middle;
          }
        }
      }

    }
}*/

.container-helper-detail{

    .make-xs-column(12);

    display: table;
    margin-top: 30px;
    margin-bottom: 30px;
    @media @tablet{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .helper-detail-img{
      .make-xs-column(2);
      .make-sm-column(2);
      .make-md-column(1);
      .make-md-column-offset(3);
      display: table-cell;
      vertical-align: middle;
      float: none;

      @media @tablet {
        padding-top: 10px;
      }

      img{
        @media @tablet {
          padding-left: 25px;
          padding-right: 25px;
        }
        @media @normal {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    .helper-detail-text{
      .make-xs-column(10);
      .make-sm-column(10);
      .make-md-column(8);
      float: none;
      background: @ubb-grigio;
      display: table-cell;
      padding: 16px 25px 22px 15px;
      font-size: 15px;
      font-style: italic;
      font-weight: 700;


      @media @tablet{
        font-size: 21px;
        padding: 30px 72px 30px 40px;
      }



      .wrapper-helper-number-text{
        display: table;

        .text{
          clear: left;
          @media @tablet{
            clear: none;
            display: table-cell;
            vertical-align: middle;
          }
        }
        .number-text{
          clear: left;
          color: @ubb-arancio;
          font-size: 30px;
          font-style: normal;
          @media @tablet{
            clear: none;
            display: table-cell;
            font-size: 50px;
            padding-right: 20px;
            vertical-align: middle;
          }
        }
      }


    }
}

}





